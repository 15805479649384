

import React from "react";
import SideBarDashboard from "./SideBarDashboard";

const Layout = ({ children }) => {
  return (
    <div className=" flex flex-col overflow-x-hidden  justify-center items-center w-full  h-auto bg-white">
      <div className="overflow-x-hidden   w-full flex  justify-start items-start">
        <div className="w-2/12 min-w-[260px] bg-custom-white flex min-h-screen h-[120vh] overflow-hidden flex-col  gap-y-4">
            <SideBarDashboard/>
        </div>
        <div className="flex flex-col justify-start items-start min-h-screen  w-9/12">
        {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
