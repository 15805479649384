    


    import { Popover, Transition } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'sonner'
import Loader from '../../Loader'
import Layout from '../Layout'
import LignesTab from '../lignesTab'
        
        const CommandeHistorique = () => {
    
    
    
    
    
            const TitleTableau = {
                type : 1,
                numero : 'Numero',
                clients:"clients",
                date:"date",
                menu :"menu",
                revenue :"revenue",
                tel :"tel",
                statutCheck :true,
                lieu :"lieu",
                
    
            }
    
           
    
            const [isOpen1, setIsOpen1] = useState(false);
            const [valueFilter,setValueFilter] = useState();
            const [stateFilter, setStateFilter] = useState("");
            const [order, setOrder] = useState();
    
            const showFilter = () =>{
              if(isOpen1){
                  setIsOpen1(false)
              }else{
                  setIsOpen1(true)
              }
          }
    
          const sortByDate = (choice) =>{
    
            setIsOpen1(false)
            setStateFilter(choice)
    
         
    
          }
    
    
         
    
          const showChoiceFilter = () =>{
            const dateString = 'date'
            const lieuString = 'lieu'
              if(stateFilter === dateString){
                  return dateString
              } else{
                return lieuString
              }
          }
    
          const checkIfChoice = () =>{
            if(stateFilter.length === 0 ){
    
              return false
    
            }else{
              return true
            }
          }
    
          const {
            data: HistoriqueAdminSide,
            isPending: loadingHistoriqueAdminSide,
            isError: errorHistoriqueAdminSide,
          } = useQuery({
            queryKey: ["HistoriqueAdminSide"],
            withCredentials: true,
            refetchInterval: 5000,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/v1/auth/receipts/users/receipts/all`,{ 
                headers : {
    
                  "Access-Control-Allow-Origin": true,
                  // Authorization :`Bearer ${tokenReturn()}`
                }
              }
              ),
            onError: (error) => {
              toast.error("une erreur est survenue");
            },
          });
    
    
    
    
          
    
    
    
          
          const [loadingGeneral, setLoadingGeneral] = useState();
    
          const [ErrorGeneral,setErrorGeneral] = useState(); 
          const historiquedata = HistoriqueAdminSide?.data?.filter((elem) => {
            return elem.delivered === true;
          });
          useEffect(()=>{
    
            setOrder(historiquedata)
            setLoadingGeneral(loadingHistoriqueAdminSide)
            setErrorGeneral(errorHistoriqueAdminSide)
    
        },[order,HistoriqueAdminSide?.data,loadingHistoriqueAdminSide,errorHistoriqueAdminSide])
    
    
        const handleChange =  (dataOrderlive) => {
    
          let  datarechercher 
    
            if(showChoiceFilter() === "lieu"){
               datarechercher =   dataOrderlive?.filter((orderLive) => orderLive.location?.toLowerCase().includes(valueFilter))
    
            }
            if(showChoiceFilter() === "date"){
              datarechercher =   dataOrderlive?.filter((orderLive) => orderLive.date?.slice(0,10).toLowerCase().includes(valueFilter))
    
            }
      
          return  datarechercher;
          }; 
         
    
          useEffect(()=>{
    
      
    
            handleChange(historiquedata)  && handleChange(historiquedata).length > 0 && checkIfChoice()
            ? setOrder(handleChange(historiquedata)) 
            : setOrder(historiquedata);
    
          
    
        
          },[handleChange(historiquedata)])
    
          if(errorHistoriqueAdminSide){
            return  <div className="w-full min-h-screen flex flex-col justify-center
            items-center">Une erreur est survenue </div>
          }

      
      
    
    
          return (
            <Layout >
    
                <div className='w-full px-7 py-8 h-full justify-start  items-start  flex flex-col'>
    
                        <section className='w-full flex flex-col gap-y-2'>
                        <div className='flex gap-x-2 justify-start items-center text-md'>
                        <svg
      width={15}
      height={15}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63443 0.704421C7.49631 0.654196 7.34309 0.66728 7.21549 0.740196L0.376931 4.64794C0.221143 4.73696 0.125 4.90263 0.125 5.08206V5.46095C0.125 5.6712 0.256534 5.85899 0.454128 5.93084L11.2718 9.86454C11.4099 9.91476 11.5631 9.90168 11.6907 9.82876L18.6231 5.86743C18.7789 5.77841 18.875 5.61274 18.875 5.43331V5.14212C18.875 4.93186 18.7435 4.74407 18.5459 4.67222L7.63443 0.704421ZM18.875 8.88856C18.875 8.50465 18.4603 8.26397 18.1269 8.45444L12.0795 11.9101C11.8243 12.056 11.5178 12.0821 11.2416 11.9817L0.795872 8.18324C0.46978 8.06466 0.125 8.30615 0.125 8.65314V10.6693C0.125 10.8796 0.256534 11.0673 0.454128 11.1392L11.2718 15.0729C11.4099 15.1231 11.5631 15.11 11.6907 15.0371L18.6231 11.0758C18.7789 10.9868 18.875 10.8211 18.875 10.6417V8.88856ZM0.454129 16.3699C0.256534 16.2981 0.125 16.1103 0.125 15.9V13.8615C0.125 13.5145 0.469779 13.273 0.795871 13.3916L11.2416 17.19C11.5178 17.2905 11.8243 17.2643 12.0795 17.1185L18.1269 13.6628C18.4603 13.4723 18.875 13.713 18.875 14.0969V15.9601C18.875 16.1395 18.7789 16.3052 18.6231 16.3942L11.7845 20.3019C11.6569 20.3749 11.5037 20.3879 11.3656 20.3377L0.454129 16.3699Z"
        fill="#111111"
      />
                        </svg>
                        <span >Cm-chicken {">"}  </span> 
                        <span >historiques</span>
                        </div>
                        <div className='flex  '>
                                <h3 className='text-xl font-bold'> Commandes </h3>
                        </div>
                        </section>
    
                        <section className='w-full  py-8 flex  '>
    
                            <div className='rounded-md w-full flex overflow-hidden' >
                                <div className='px-1 w-[144px]  relative flex justify-center items-center text-white bg-red-500'>
                                <Popover>
      
      <>
       <div className='w-full  relative flex '>
         <Popover.Button className='text-sm relative flex justify-between items-center capitalize ' >
         <button className='flex gap-x-3 xl:gap-x-5' onClick={()=> showFilter()} >
         {!checkIfChoice() && 
         <>
              <span className='lg:flex hidden'>Trier par</span> <svg xmlns="http://www.w3.org/2000/svg" className={` ${isOpen1 ? 'rotate-180' : ''} `} viewBox="0 0 24 24" width={20} height={20} color={"#ffffff"} fill={"none"}>
        <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    </>
    }
    {checkIfChoice() && 
    <>
              <span className=''>  {showChoiceFilter()} </span> 
              <svg xmlns="http://www.w3.org/2000/svg" className={` ${isOpen1 ? 'rotate-180' : ''} `} viewBox="0 0 24 24" width={20} height={20} color={"#ffffff"} fill={"none"}>
        <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    </>
    
    }
    </button>
         </Popover.Button>
         </div>
         <Transition
         appear show={isOpen1} 
    as={Fragment} 
    enter="transition ease-out duration-200"
    enterFrom="opacity-0 translate-y-1"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-in duration-150"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 translate-y-1"
    
    >
         <Popover.Panel className={`fixed flex text-sm z-10 flex-col justify-center items-center  ${checkIfChoice() ? "-translate-x-9" : "-translate-x-5" }  translate-y-3    bg-white  w-[138px] h-auto   shadow-lg `}>
          <button onClick={(e)=> sortByDate('date')} className='w-full hover:bg-custom-white px-4  text-red-500 flex gap-x-2 justify-start items-center text-center  py-2'> 
          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.25 6.125C1.25 4.94649 1.25 4.35723 1.61612 3.99112C1.98223 3.625 2.57149 3.625 3.75 3.625H11.25C12.4285 3.625 13.0178 3.625 13.3839 3.99112C13.75 4.35723 13.75 4.94649 13.75 6.125V6.125C13.75 6.41963 13.75 6.56694 13.6585 6.65847C13.5669 6.75 13.4196 6.75 13.125 6.75H1.875C1.58037 6.75 1.43306 6.75 1.34153 6.65847C1.25 6.56694 1.25 6.41963 1.25 6.125V6.125Z" fill="#F93A47"/>
    <path d="M1.25 11.75C1.25 12.9285 1.25 13.5178 1.61612 13.8839C1.98223 14.25 2.57149 14.25 3.75 14.25H11.25C12.4285 14.25 13.0178 14.25 13.3839 13.8839C13.75 13.5178 13.75 12.9285 13.75 11.75V8.625C13.75 8.33037 13.75 8.18306 13.6585 8.09153C13.5669 8 13.4196 8 13.125 8H1.875C1.58037 8 1.43306 8 1.34153 8.09153C1.25 8.18306 1.25 8.33037 1.25 8.625V11.75Z" fill="#F93A47"/>
    <path d="M4.375 2.375L4.375 4.25" stroke="#F93A47" strokeWidth="1.25" strokeLinecap="round"/>
    <path d="M10.625 2.375L10.625 4.25" stroke="#F93A47" strokeWidth="1.25" strokeLinecap="round"/>
    </svg>
    
    <span> Date </span>
    
             </button>
             <button  onClick={(e)=> sortByDate('lieu')} className='w-full hover:bg-custom-white px-4 text-red-500  flex gap-x-2 justify-start items-center text-center  py-2'> 
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5576 7.18577C6.53436 6.59424 6.54444 5.97121 6.55975 5.5059C6.97037 5.7253 7.51497 6.02808 8.01563 6.34398C8.31812 6.53484 8.59679 6.72562 8.81753 6.90166C9.05087 7.08773 9.17485 7.22289 9.21759 7.29691C9.26032 7.37093 9.31538 7.54589 9.35986 7.84099C9.40194 8.12018 9.42783 8.45691 9.44187 8.8143C9.46512 9.40583 9.45504 10.0289 9.43973 10.4942C9.0291 10.2748 8.4845 9.97199 7.98384 9.65609C7.68135 9.46524 7.40269 9.27445 7.18194 9.09842C6.94861 8.91234 6.82463 8.77718 6.78189 8.70316C6.73915 8.62914 6.68409 8.45419 6.63962 8.15908C6.59754 7.87989 6.57164 7.54317 6.5576 7.18577Z" stroke="#F93A47" strokeWidth="0.9375"/>
    <circle cx="8" cy="8" r="5.625" stroke="#F93A47" strokeWidth="0.9375"/>
    </svg>
    
    <span> Lieu </span>
    
             </button>
         </Popover.Panel> 
         </Transition>
       </>
    
    </Popover>
    
                                </div>
                                <div className='px-4 w-full py-3 justify-start items-center bg-[#F6F6F6] flex gap-x-1'>
    
                                <svg width="25" height="25" className=' ' viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.875" cy="6.875" r="4.375" stroke="#B0B0B0" strokeWidth="1.25"/>
    <path d="M12.5 12.5L10.625 10.625" stroke="#B0B0B0" strokeWidth="1.25" strokeLinecap="round"/>
    </svg>
    <input type='text'  onChange={(e)=>setValueFilter(e.target.value)} value={valueFilter} className='  outline-none w-full h-full bg-transparent' placeholder={!checkIfChoice() ? "Recherche par Date, Lieu..." : showChoiceFilter()  }  />
    
                                </div>
                            </div>  
    
                        </section>
    
                        <section className='tableau w-full overflow-x-auto flex justify-start items-start flex-col'>
                        
                        <LignesTab  {...TitleTableau} />
                          <div className='w-full'>
                       {loadingGeneral  ? 
                         <div className='w-full h-full flex flex-col min-h-[250px]  justify-center '>
                     
                        <Loader/>
                       </div>
                       : ErrorGeneral ? (
                        <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
                          <p className="font-bold text-sm  ">
                            Erreur lors de la récupération des données
                          </p>
                        </div>
                      ) :
                        <div className='w-full flex justify-start items-start  flex-col'>
    
                        {order?.length > 0 ? ( order?.map((item)=>{
                          return(
                              <LignesTab key={item.id}  {...item} />
                          )
                        })) : (
                          <div className="w-full h-[240px] text-gray-400 flex justify-center items-center">
                            <p className="font-bold text-xl ">Aucune commande trouvee </p>
                          </div>
                        )}
                     
                       </div>
                       }
    
                         
                   
                     
                        </div>
                        </section>
    
                </div>
              
            </Layout>
          )
        }
        
        export default CommandeHistorique
        