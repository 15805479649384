

        

    import React from 'react';
        
        const LigneTabServiceTraiteur = React.forwardRef((propsLignesTabs,forwardRef ) => {
      
           const  propsLignesTab = {...propsLignesTabs}
    
            propsLignesTab.numero = propsLignesTab.id
            
        propsLignesTab.date = propsLignesTab.date?.slice(0,10)
    
  
           
    
           
                   
          return (
            <div 
      ref={forwardRef} className={`w-full flex border-b  min-h-12  overflow-x-auto min-w-[1250px] gap-x-4  pl-2  border-[#D1D1D1] ${propsLignesTab.type === 1  ? "text-white bg-red-500"  : "text-black bg-white"} `}>
                <div className='w-1/12 overflow-hidden flex justify-start items-center'>
    
                  {propsLignesTab.type === 1 ?  
    
    <span className='text-sm capitalize  '> # </span>
                  :   
    
                  <span className='text-sm capitalize  '> {propsLignesTab.id} </span>
                  }
                </div>
                <div className='w-2/12 flex justify-start items-center'>
                    <span className='text-sm capitalize  '> {propsLignesTab.date} </span>
                </div>
              
                  <div className='w-3/12  overflow-hidden flex justify-start items-center'>
                  {propsLignesTab.type === 1 ? 
                   <span className='text-sm w-full capitalize '> 
                    
                   Email
                   
                   
                    </span>
                   : 
                   <span className='text-sm capitalize  truncate'> {propsLignesTab.email} </span>
               
                  }
                </div>

                <div className='w-3/12 flex overflow-hidden justify-start items-center'>
                  {propsLignesTab.type === 1 ? 
                   <span className='text-sm w-full capitalize '> 
                    
                   Type Evenement 
                   
                   
                    </span>
                   : 
                   <span className='text-sm capitalize  truncate'> {propsLignesTab.eventType} </span>
               
                  }
                </div>

                <div className='w-3/12 flex overflow-hidden justify-start items-center'>
                  {propsLignesTab.type === 1 ? 
                   <span className='text-sm w-full capitalize '> 
                    
                   Date Evenement 
                    </span>
                   : 
                   <span className='text-sm capitalize  truncate'> {propsLignesTab.eventDate} </span>
               
                  }
                </div>
                   
                    
    
    
    
                <div className='w-2/12 flex overflow-hidden justify-start items-center'>
                {propsLignesTab.type === 1 ? 
                    <span className='text-sm capitalize '> Tel </span> :
                      <span className='text-sm capitalize '> {propsLignesTab.phone } </span>
                    }
                </div>
                <div className='w-2/12 flex justify-between items-center'>
                {propsLignesTab.type === 1 ? 
                <div className='w-full flex justify-between items-center'>
                    <span className='text-sm'> lieu </span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16699 14.1665H10.8337" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
    <path d="M4.16699 10H9.16699" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
    <path d="M4.16699 5.83301H7.50033" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
    <path d="M15.833 5L18.333 7.5M15.833 5L13.333 7.5M15.833 5L15.833 15" stroke="white" strokeWidth="1.25"/>
    </svg> </div> : 
    
                    
    <span className='text-sm '> {propsLignesTab.localisation} </span>
    
                }
                </div>


    
                <div className='w-2/12 flex text-wrap  overflow-hidden justify-start items-center'>
  {propsLignesTab.type !== 1 ? (
  <span className='text-sm text-wrap w-full h-full  text-left  break-words '>{propsLignesTab.description}</span>
  ) : (
    <span className='text-sm '>Description</span>
  )}
</div>
             
            </div>
          )
        })
        
        export default LigneTabServiceTraiteur
        