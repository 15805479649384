import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "swiper/css";
import "./App.css";
import ComplementProvider from "./components/Complementsprovider";
import CommandeHistorique from "./components/Dashboard/pages/commandeHistorique";
import DashboardCommande from "./components/Dashboard/pages/dashboardCommande";
import DashboardHome from "./components/Dashboard/pages/dashboardHome";
import DashboardServiceTraiteur from "./components/Dashboard/pages/dashboardServiceTraiteur";
import HistoriquePage from "./components/historiquePage";
import ServiceTraiteur from "./components/serviceTraiteur";
import Page from "./page";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Page />,
    },
    {
      path: "historique",
      element: <HistoriquePage />,
    },
    {
      path: "dashboardCommande",
      element: <DashboardCommande />,
    },
    {
      path: "dashboardHome",
      element: <DashboardHome />,
    },
    {
      path: "dashboardServiceTraiteur",
      element: <DashboardServiceTraiteur />,
    },
    {
      path: "serviceTraiteur",
      element: <ServiceTraiteur />
    },
    {
      
      path: "commandeHistorique",
      element: <CommandeHistorique/>
    }
  ]);

  const queryClient = new QueryClient();

  return (
    <div className="w-full">
    <Helmet>
        <meta charset="UTF-8" />
    <title>CM - Chicken</title>
    <meta name="robots" content="index, follow" />
    <meta property="og:title" content="CM-chiken" />     
   <meta property="og:description" content="Découvrez le meilleur poulet du Mboa en commandant dès maintenant sur notre site. Nous vous proposons une sélection de produits de qualité, préparés avec soin par nos équipes. Nos poulets sont élevés dans le respect des normes sanitaires et alimentaires, pour vous garantir une viande saine et savoureuse." />
    <meta name="twitter:title" content="CM-chiken" />
    <meta name="twitter:description" content="Découvrez le meilleur poulet du Mboa en commandant dès maintenant sur notre site. Nous vous proposons une sélection de produits de qualité, préparés avec soin par nos équipes. Nos poulets sont élevés dans le respect des normes sanitaires et alimentaires, pour vous garantir une viande saine et savoureuse." />
    <meta name="twitter:image" content="https://images.unsplash.com/photo-1501200291289-c5a76c232e5f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
    <meta property="og:image" content="https://images.unsplash.com/photo-1501200291289-c5a76c232e5f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
     <meta name="description" content="Découvrez le meilleur poulet du Mboa en commandant dès maintenant sur notre site. Nous vous proposons une sélection de produits de qualité, préparés avec soin par nos équipes. Nos poulets sont élevés dans le respect des normes sanitaires et alimentaires, pour vous garantir une viande saine et savoureuse. " /> 
     <link rel="canonical" href="https://cmchikenfront-end.onrender.com/" />
    <meta property="og:url" content="https://cmchikenfront-end.onrender.com/" /> 
    </Helmet>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ComplementProvider>
          <RouterProvider router={router} />
        </ComplementProvider>
      </QueryClientProvider>
    </RecoilRoot>

    </div>
  );
}

export default App;
