import Apropos from "./Apropos";
import Footer from "./Footer";
import Header from "./Header";
import MenuTraiteur from "./MenuTraiteur";
import Reservation from "./Reservation";
import ServiceTraiteurComponent from "./ServiceTraiteurComponent";


function ServiceTraiteur() {
  return (
    <div className="w-full flex justify-center items-center">
    <div className="w-full flex flex-col overflow-hidden max-w-[1450px] justify-center items-center">
      <Header />
      <Apropos />
      <ServiceTraiteurComponent />
      <MenuTraiteur />
      <Reservation />
      <Footer />
      </div>
    </div>
  );
}

export default ServiceTraiteur;
