import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Cookies from "js-cookie";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { tokenReturn } from "../atom";
import Loader from "./Loader";
const AuthModal = ({ isOpenState, onClose, isOpenLogin, otherOption = true }) => {

 

  const fermer = () => {
    onClose();
  };

  const [infoSignUp, setInfoSignUp] = useState({
    usernameSignUp: "",
    passwordSignUp: "",
  });

  const navigate = useNavigate()

  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const inputPasswordRefSignUp  = useRef(null)

  const [isVisblePasswordSignUp,setisVisblePasswordSignUp] = useState(false)

  const changeVisibilitySignUp = () => {
    
    if (inputPasswordRefSignUp.current && inputPasswordRefSignUp.current.type === "password") {
      inputPasswordRefSignUp.current.type = "text";
      setisVisblePasswordSignUp(true);
    } else {
      inputPasswordRefSignUp.current.type = "password";
      setisVisblePasswordSignUp(false);
    }
  };

  const checkvalideInputSignUp = Object.values(infoSignUp).every((value) => value !== " ");

  const handleSubmitSignUp = async (e) => {
    e.preventDefault();
    if (checkvalideInputSignUp) {
      try {
        setLoadingSignUp(true);
        await axios
          .post(
            "https://cmchickenbackend.onrender.com/api/v1/auth/client/register",
            {
              username:infoSignUp.usernameSignUp,
              password:infoSignUp.passwordSignUp,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": true,
              },
            }
          )
          .then((res) => {

            handleShowSignIn()
           
            setInterval(() => {
              setLoadingSignUp(false);
            }, 1000);
            toast.success("Vous êtes inscris avec succes  !");
            // fermer();
          })
          .catch((err) => {
            console.log(err, 88);
            toast.error("Veuillez rentrer les bonnes donnees");
            setInterval(() => {
              setLoadingSignUp(false);
            }, 1000);
          })
          .finally(() => {});
      } catch (err) {
        console.log(err, 88);
      }
    } else {
      toast("veuillez remplir tous les champs correctement");
    }
  };

  const [showSignInState,setShowSignInState] = useState(isOpenLogin)

  useEffect(()=>{
    setShowSignInState(isOpenLogin)
  },[isOpenLogin])

 
  const handleShowSignIn = () => {
    setShowSignInState(true)
  }

  const handleShowSignUp  = () => {
    setShowSignInState(false)
  }








  const [infoSignIn, setInfoSignIn] = useState({
    usernameSignIn: "",
    passwordSignIn: "",
  });
  const [loadingSignIn, setLoadingSignIn] = useState(false);

  const checkvalideInputSignIn = Object.values(infoSignIn).every((value) => value !== "");

  const redirectUser  = (roleuser) =>{
    if(roleuser === "CLIENT"){
      // navigate.push("/")
    }

    if(roleuser === "ADMIN"){
      navigate("/dashboardHome")
    }

    if(roleuser === "WAITER"){
      navigate("/dashboardCommande")
    }

    if(roleuser === "COOK"){
      navigate("/dashboardCommande")
    }


  }

  const checkTypeOfUser = async () =>{
    try {
      await axios
        .get(
          "https://cmchickenbackend.onrender.com/api/v1/auth/user/get",
          {
            headers: {
              
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": true,
              Authorization :`Bearer ${tokenReturn()}`
            },
          }
        )
        .then((res) => {
          
       
          redirectUser(res.data)
      

        })
        .catch((err) => {
          console.log(err, 88);
        })
        .finally(() => {});
    } catch (err) {
      console.log(err, 88);
    }
  }

  const inputPasswordRefSignIn  = useRef(null)
  const [isVisblePasswordSignIn,setIsVisblePasswordSignIn] = useState(false)

  const changeVisibilitySignIn = () => {
    
    if (inputPasswordRefSignIn.current && inputPasswordRefSignIn.current.type === "password") {
      inputPasswordRefSignIn.current.type = "text";
      setIsVisblePasswordSignIn(true);
    } else if (inputPasswordRefSignIn.current) {
      inputPasswordRefSignIn.current.type = "password";
      setIsVisblePasswordSignIn(false);
    }
  };
  


  const handleSubmitSignIn = async (e) => {
    e.preventDefault();
    if (checkvalideInputSignIn) {
      try {
        setLoadingSignIn(true);
        await axios
          .post(
            "https://cmchickenbackend.onrender.com/api/v1/auth/employee/login",
            {
              username:infoSignIn.usernameSignIn,
              password:infoSignIn.passwordSignIn,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": true,
              },
            }
          )
          .then((res) => {
            const token = res.data.accessToken;
            Cookies.set("token77xAplD", token, {
              expires: 7,
              secure: true,
              sameSite: "strict",
            });

            setInterval(() => {
              setLoadingSignIn(false);
            }, 1000);
            checkTypeOfUser()
            toast.success("Vous êtes connecté !");
            fermer();
          })
          .catch((err) => {
            toast.error("Veuillez rentrer les bons identifiants");
            setInterval(() => {
              setLoadingSignIn(false);
            }, 1000);
          })
          .finally(() => {});
      } catch (err) {
        console.log(err, 88);
      }
    } else {
      toast("veuillez remplir tous les champs correctement");
    }
  };

  const inputRefSignIn = useRef(null)

  return (
    <Transition appear show={isOpenState} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={fermer}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-filter-sm inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md  flex flex-col justify-center items-center gap-y-4 transform overflow-hidden rounded-2xl bg-white px-4 text-left align-middle shadow-xl transition-all">
                <div className="flex w-full flex-col justify-center items-center">
                  <div className="w-full  flex py-4 justify-end items-end ">
                    <button
                      tabIndex={1}
                      onClick={fermer}
                      className="text-red-500 focus:outline  outline-1 outline-black  text-3xl font-medium"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-x"
                      >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">

                      {!showSignInState ? 

                      <div
                      className=" w-full rounded-2xl flex gap-y-4  bg-white flex-col
  "
                    >
                      <h1 className="text-3xl font-bold  text-[#11111] py-1  w-full text-center">
                        S'inscrire
                      </h1>
                      <form
                        onSubmit={handleSubmitSignUp}
                        className="flex  py-8 flex-col justify-center gap-y-4 px-2 items-center  w-full "
                      >
                        <span className="sr-only"> Nom </span>
                        <div className="flex flex-col w-full gap-y-8">
                          <div
                            tabIndex={1}
                            className="rounded-full focus:outline  outline-1 outline-black   w-full overflow-hidden  justify-start items-center  border border-gray-400  bg-white  flex h-16 "
                          >
                            <svg
                              width={30}
                              height={30}
                              className="w-2/12"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24.66 25.559c-.57-1.595-1.826-3.004-3.572-4.01C19.34 20.546 17.2 20 15 20s-4.341.545-6.088 1.55c-1.746 1.005-3.002 2.414-3.571 4.009"
                                stroke="#454545"
                                strokeWidth={2}
                                strokeLinecap="round"
                              />
                              <circle
                                cx={15}
                                cy={10}
                                r={5}
                                stroke="#454545"
                                strokeWidth={2}
                                strokeLinecap="round"
                              />
                            </svg>
                            <input
                              onChange={(e) =>
                                setInfoSignUp({ ...infoSignUp, usernameSignUp: e.target.value })
                              }
                              value={infoSignUp.usernameSignUp}
                              className="w-full h-full text-sm sm:text-lg border-[#111111]/70 placeholder-[#888888] px-1 outline-none "
                              placeholder="Email d’utilisateur"
                              type="email"
                            />
                          </div>
                          <span className="sr-only"> Mot de passe </span>
                          <div
                            tabIndex={1}
                            className="rounded-full focus:outline  outline-1 outline-black   w-full overflow-hidden  justify-start items-center  border border-gray-400  bg-white  flex h-16 "
                          >
                            <svg
                              width={30}
                              height={30}
                              className="w-3/12"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 15.25c0-1.886 0-2.828.586-3.414.586-.586 1.528-.586 3.414-.586h12c1.886 0 2.828 0 3.414.586.586.586.586 1.528.586 3.414v5c0 2.828 0 4.243-.879 5.121-.878.879-2.293.879-5.121.879h-8c-2.828 0-4.243 0-5.121-.879C5 24.493 5 23.078 5 20.25v-5z"
                                stroke="#454545"
                                strokeWidth={2}
                              />
                              <path
                                d="M20 10V8.75a5 5 0 00-5-5v0a5 5 0 00-5 5V10"
                                stroke="#454545"
                                strokeWidth={2}
                                strokeLinecap="round"
                              />
                            </svg>
                            <input
                              onChange={(e) =>
                                setInfoSignUp({ ...infoSignUp, passwordSignUp: e.target.value })
                              }
                              ref={inputPasswordRefSignUp}
                              value={infoSignUp.passwordSignUp}
                              className="w-full h-full text-sm sm:text-lg border-[#111111]/70 placeholder-[#888888] px-4 outline-none "
                              placeholder="Mot de passe"
                              type="password"
                            />
                           {isVisblePasswordSignUp ? (
  <svg
    onClick={() => changeVisibilitySignUp()}
    width={25}
    className="w-2/12"
    height={25}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 6.25c-6.805 0-10.586 5.293-11.93 7.646-.276.482-.414.723-.4 1.083.016.36.178.596.502 1.066C4.772 18.367 9.117 23.75 15 23.75c5.883 0 10.227-5.383 11.828-7.705.324-.47.486-.706.501-1.066.015-.36-.123-.6-.398-1.083C25.586 11.543 21.805 6.25 15 6.25z"
      stroke="#6D6D6D"
      strokeWidth={2.5}
    />
    <circle cx={15} cy={15} r={5} fill="#6D6D6D" />
  </svg>
) : (
  <svg
  onClick={() => changeVisibilitySignUp()}
    xmlns="http://www.w3.org/2000/svg"
    className="w-2/12"
    viewBox="0 0 24 24"
    width={25}
    height={25}
    color={"#4a4a4a"}
    fill={"none"}
  >
    <path
      d="M22 8C22 8 18 14 12 14C6 14 2 8 2 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15 13.5L16.5 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 11L22 13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 13L4 11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13.5L7.5 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)}
                          </div>
                        </div>
                        <div className="w-full flex justify-between px-4">
                          <div className="w-auto flex justify-center items-center gap-x-2 ">
                            <input className="h-6 w-6" type={"checkbox"} />

                            <NavLink
                              tabIndex={1}
                              to="/"
                              className="text-sm text-red-500 "
                            >
                              J’accepte les conditions d’utilisateurs
                            </NavLink>
                          </div>
                        </div>

                        <div className="flex flex-col gap-y-5  pt-4 w-full h-auto">
                          <button
                            className="w-full h-[70px] cursor-pointer rounded-full font-bold text-xl bg-red-500 text-white px-4  "
                            value={"Sign Up"}
                            type="submit"
                          >
                            {loadingSignUp ? <Loader /> : "Sign Up"}
                          </button>
                          {otherOption ? (
                            <div className="w-full text-lg gap-x-4 py-4 flex justify-center items-center">
                              <span className="">Déjà un compte ? </span>
                              <button
                              type="button"
                                tabIndex={1}
                                onClick={() => handleShowSignIn()}
                                className="text-red-500"
                              >
                                Se connecter
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </form>
                    
                    </div>
                
                          : 
                          <div
                          className=" w-full z-30 rounded-2xl flex gap-y-4  bg-white flex-col
        "
                        >
                          <h1 className=" text-2xl sm:text-2xl  md:text-3xl font-bold  text-[#11111] py-2 sm:py-2  w-full text-center">
                            se connecter
                          </h1>
                          <form
                            onSubmit={handleSubmitSignIn}
                            className="flex  py-8 flex-col justify-center gap-y-4 px-2 items-center  w-full "
                          >
                            <span className="sr-only"> Nom </span>
                            <div className="flex flex-col w-full gap-y-4 sm:gap-y-4">
                              <div className="rounded-full focus:outline  outline-1 outline-black  w-full overflow-hidden  justify-start items-center  border border-gray-400  bg-white  flex h-12 sm:h-16 ">
                                <svg
                                  width={25}
                                  height={25}
                                  className="w-2/12 pl-2"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M24.66 25.559c-.57-1.595-1.826-3.004-3.572-4.01C19.34 20.546 17.2 20 15 20s-4.341.545-6.088 1.55c-1.746 1.005-3.002 2.414-3.571 4.009"
                                    stroke="#454545"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                  />
                                  <circle
                                    cx={15}
                                    cy={10}
                                    r={5}
                                    stroke="#454545"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                  />
                                </svg>
                                <input
                                  ref={inputRefSignIn}
                                  onChange={(e) =>
                                    setInfoSignIn({ ...infoSignIn, usernameSignIn: e.target.value })
                                  }
                                  value={infoSignIn.usernameSignIn}
                                  className="w-full h-full text-sm sm:text-lg z-50 border-[#111111]/70 placeholder-[#888888] px-0 outline-none "
                                  placeholder="Email d’utilisateur"
                                  type="text"
                                  autoFocus
                                />
                              </div>
                              <span className="sr-only"> Mot de passe </span>
                              <div className="rounded-full  w-full overflow-hidden  z-50 justify-start items-center  border border-gray-400  bg-white  flex h-12 sm:h-16 ">
                                <svg
                                  width={25}
                                  height={25}
                                  className="w-2/12 pl-2"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5 15.25c0-1.886 0-2.828.586-3.414.586-.586 1.528-.586 3.414-.586h12c1.886 0 2.828 0 3.414.586.586.586.586 1.528.586 3.414v5c0 2.828 0 4.243-.879 5.121-.878.879-2.293.879-5.121.879h-8c-2.828 0-4.243 0-5.121-.879C5 24.493 5 23.078 5 20.25v-5z"
                                    stroke="#454545"
                                    strokeWidth={2}
                                  />
                                  <path
                                    d="M20 10V8.75a5 5 0 00-5-5v0a5 5 0 00-5 5V10"
                                    stroke="#454545"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                  />
                                </svg>
                                <input
                                  onChange={(e) =>
                                    setInfoSignIn({ ...infoSignIn, passwordSignIn: e.target.value })
                                  }
                                  ref={inputPasswordRefSignIn}
                                  value={infoSignIn.passwordSignIn}
                                  className="w-full h-full text-sm sm:text-lg border-[#111111]/70 placeholder-[#888888] px-1 outline-none "
                                  placeholder="Mot de passe"
                                  type="password"
                                  name="password"
                                /> 
                                
      {isVisblePasswordSignIn ? (
        <svg
          onClick={() => changeVisibilitySignIn()}
          width={25}
          className="w-2/12"
          height={25}
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 6.25c-6.805 0-10.586 5.293-11.93 7.646-.276.482-.414.723-.4 1.083.016.36.178.596.502 1.066C4.772 18.367 9.117 23.75 15 23.75c5.883 0 10.227-5.383 11.828-7.705.324-.47.486-.706.501-1.066.015-.36-.123-.6-.398-1.083C25.586 11.543 21.805 6.25 15 6.25z"
            stroke="#6D6D6D"
            strokeWidth={2.5}
          />
          <circle cx={15} cy={15} r={5} fill="#6D6D6D" />
        </svg>
      ) : (
        <svg
        onClick={() => changeVisibilitySignIn()}
          xmlns="http://www.w3.org/2000/svg"
          className="w-2/12"
          viewBox="0 0 24 24"
          width={25}
          height={25}
          color={"#4a4a4a"}
          fill={"none"}
        >
          <path
            d="M22 8C22 8 18 14 12 14C6 14 2 8 2 8"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M15 13.5L16.5 16"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 11L22 13"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 13L4 11"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 13.5L7.5 16"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
                              </div>
                            </div>
                            <div className="w-full flex sm:flex-row flex-col justify-start items-start gap-y-2 sm:gap-y-1 sm:justify-between px-4">
                              <div
                                tabIndex={1}
                                className="w-auto flex  justify-center items-center gap-x-2 "
                              >
                                <input className="h-6 w-6" type={"checkbox"} />
                                <span className="text-xs"> Reste connecte </span>
                              </div>
                              <div tabIndex={1} className="">
                                <NavLink to="/" className="text-sm text-red-500 ">
                                  Mot de passe oublié ?
                                </NavLink>
                              </div>
                            </div>
                            <button
                              tabIndex={1}
                              className="w-full h-10 sm:h-[70px] mt-8  sm:mt-8 cursor-pointer rounded-full font-bold text-xl bg-red-500 text-white px-4  "
                              type="submit"
                            >
                              {loadingSignIn ? <Loader /> : "se connecter"}
                            </button>
                            {otherOption ? (
                              <div className="flex flex-col gap-y-5 py-4 w-full h-auto">
                                <div className="w-full text-lg gap-x-4 py-2 flex justify-center items-center">
                                  <span className="">Pas de compte ? </span>
                                  <button
                                  type="button"
                                    tabIndex={1}
                                    className="text-red-500"
                                      onClick={() => handleShowSignUp()}
                                  >
                                    S'inscrire
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </form>
                        </div>
                      }

                  
              
                
                </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AuthModal;
