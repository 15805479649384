import React from "react";
import Skeleton from "react-loading-skeleton";

const Loadercomplements = () => {
  return (
    <Skeleton
      className="flex w-full animate-pulse  h-28  cursor-pointer transition-all ease duration-500 
  hover:bg-transparent border 
  border-gray-200 bg-gray-400/20 rounded-xl p-2"
    />
  );
};

export default Loadercomplements;
