import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingCard = () => {
  return (
    <Skeleton
      className="flex w-full animate-pulse  h-[347px]  sm:h-[450px] cursor-pointer transition-all ease duration-500 
  hover:bg-transparent border 
  border-gray-200 bg-gray-400/20 rounded-lg p-2"
    />
  );
};

export default LoadingCard;
