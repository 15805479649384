import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useCallback, useRef } from "react";
import { toast } from "sonner";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadingCard from "./LoadingCard";
import ArrowDown from "./images/arrowDow.png";
import ArrowLeft from "./images/arrowLeft.svg";
import poulet from "./images/poulet.png";
import ProductCard from "./productCard";
import ProductPresentCard from "./productPresentCard";
const Chicken = () => {
  const {
    data: productChicken,
    isPending: loadingchicken,
    isError: errorchicken,
  } = useQuery({
    queryKey: ["productChciken"],
    withCredentials: true,
    queryFn: () =>
      axios.get(
        `https://cmchickenbackend.onrender.com/api/v1/auth/category/259/products`
      ),
    onError: (error) => {
      toast.error("une erreur est survenue");
    },
  });

  const sliderRef = useRef();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);


    if(errorchicken){

      <div className="w-full min-h-screen flex flex-col justify-center
    items-center">Une erreur est survenue </div>
      
    }
  
  

  return (
    <div id="poulet" className="flex justify-center items-start md:items-center w-full">
      <div className=" flex flex-col md:hidden  relative sm:py-4 sm:p-8 gap-y-4  w-full  sm:mt-4   navigation  ">
        <div className="flex gap-x-3 sm:gap-x-4 pb-4 pt-3 justify-center items-center">
          <h2 className="text-3xl sm:text-5xl font-medium font2">
            {" "}
            special poulet{" "}
          </h2>
          <img
            src={poulet}
            className="h-20 w-20 object-contain "
            alt={"poulet"}
          />
          <img
            className="object-contain  h-16 w-16"
            src={ArrowLeft}
            alt="poulet"
          />
        </div>
        <div className="w-full">
          <Swiper
            ref={sliderRef}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            slidesPerView={2}
            navigation={true}
            className="px-8"
          >
            {loadingchicken ? (
              <LoadingCard />
            ) : errorchicken ? (
              <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
                <p className="font-bold text-sm  ">
                  Erreur lors de la récupération des données
                </p>
              </div>
            ) : productChicken.data?.length > 0 ? (
              productChicken.data?.map((item) => {
                return (
                  <SwiperSlide key={item?.id} className="w-full relative   ">
                    <ProductCard
                      id={item?.id}
                      key={item?.id}
                      price={item?.price}
                      description={item?.description}
                      descriptionprice={"description des prix"}
                      title={item?.name}
                      image={"data:image/jpg;base64," + item?.image}
                      image2={poulet}
                    />
                  </SwiperSlide>
                );
              })
            ) : (
              <div className="w-full justify-center items-center">
                <p>Aucun produit disponible</p>
              </div>
            )}
          </Swiper>
          {!loadingchicken && sliderRef.current?.children?.length > 2 && (
            <div className="absolute top-[55%]   left-0 z-30  w-full flex justify-between">
              <div
                className="prev-arrow absolute bg-slate-400/40 pl-4 flex justify-center items-center w-12   h-16 rounded-lg -left-[4%] "
                onClick={() => handlePrev()}
              >
                <svg
                  width={23}
                  height={23}
                  className="rotate-180"
                  viewBox="0 0 93 123"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_180_2)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M92.2 60.97L0 122.88V0L92.2 60.97Z"
                      fill="red"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_180_2">
                      <rect width="92.2" height="122.88" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                className="prev-arrow absolute bg-slate-400/40  flex justify-center items-center w-12   h-16 rounded-lg left-[91%] "
                onClick={() => handleNext()}
              >
                <svg
                  width={23}
                  height={23}
                  viewBox="0 0 93 123"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_180_2)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M92.2 60.97L0 122.88V0L92.2 60.97Z"
                      fill="red"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_180_2">
                      <rect width="92.2" height="122.88" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="max-w-[1450px] xl:px-5 px-8 md:pt-8  md:py-16 gap-y-4 hidden md:grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-x-4 xl:grid-cols-4 flex-col  w-full justify-center items-center  ">
        <ProductPresentCard
          image={poulet}
          title={"Spéciale Poulet"}
          image2={ArrowDown}
          link={"/"}
          titlelink={"Voir plus"}
        />

        {loadingchicken  ? (
          <LoadingCard />
        ) : errorchicken ? (
          <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
            <p className="font-bold text-sm  ">
              Erreur lors de la récupération des données
            </p>
          </div>
        ) : productChicken.data?.length > 0 ? (
          productChicken.data?.map((item) => {
            return (
              <ProductCard
                id={item?.id}
                key={"8/" + item?.id}
                price={item?.price}
                description={item?.description}
                descriptionprice={"description des prix"}
                title={item?.name}
                image={"data:image/jpg;base64," + item?.image}
                image2={poulet}
              />
            );
          })
        ) : (
          <div className="w-full justify-center items-center">
            <p>Aucun produit disponible</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chicken;
