import axios from "axios";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { toast } from "sonner";
import { checkConnect, infoUser, tokenReturn } from "../atom";
import Loader from "./Loader";

const UpdateMotdepasse = () => {
  const email = useRecoilValue(infoUser);

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const info = {
    username: `${email}`,
    password,
  };

  const resetUserInfo = async () => {
    try {
      setLoading(true);
      await axios
        .put(
          "https://cmchickenbackend.onrender.com/api/v1/auth/user/credentials/reset",
          info,
          {
            headers: {
              "Access-Control-Allow-Origin": true,
              Authorization: `Bearer ${tokenReturn()}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setPassword(" ");
          toast.success("Modification reuissite");
        })
        .finally(() => {
          setInterval(() => {
            setLoading(false);
          }, 1000);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length > 0) {
      resetUserInfo();
    } else {
      toast.error("veuillez remplir tous les champs correctement ");
    }
  };


  return (
    <div className="xl:flex  hidden h-[600px] bg-white p-4 rounded-2xl justify-center items-center flex-col w-4/12">
        <h1 className="text-lg text-gray-700 font-bold pt-2" > Mettre à jour vos informations  </h1>
      <div className="flex flex-col  w-full h-full  justify-center items-center">
        <div className="min-w-36  font-bold min-h-36 rounded-full bg-yellow-400 flex justify-center items-center">
          <span className="font-bold uppercase text-5xl  text-white">
            {" "}
            {email.slice(0, 2)}{" "}
          </span>
        </div>
        <h2 className="text-lg text-gray-700  py-1"> {email}</h2>
        <div className="text-md flex justify-center h-auto items-center gap-x-4">
          {checkConnect() ? (
            <div className="w-full flex gap-x-2  justify-center items-center">
              <span> En ligne </span>
              <div className="h-4 w-4 rounded-full bg-green-400"></div>
            </div>
          ) : (
            <div className="w-full flex gap-x-2 justify-center items-center">
              <span> Hors ligne </span>
              <div className="h-4 w-4 rounded-full bg-red-500"></div>
            </div>
          )}
        </div>
        <div className="flex flex-col   px-2 w-full">
          <form
            onSubmit={handleSubmit}
            className="flex gap-y-4 pt-6 flex-col px-2 w-full"
          >
            <div
              tabIndex={1}
              className="flex rounded-2xl outline-1 outline-black  overflow-hidden bg-[#D4D4D4]/40 p-2 flex-col w-full border-2 border-[#878787]/20 "
            >
              <span className="text-sm text-[#969696] font-medium ">
                {" "}
                Email{" "}
              </span>
              <input
                type="email"
                value={email}
                className="border-none rounded-xl outline-none h-6 bg-transparent"
              />
            </div>
            <div
              tabIndex={1}
              className="flex rounded-2xl gap-y-1  outline-1 outline-black overflow-hidden bg-[#D4D4D4]/40 p-2 flex-col w-full border-2 border-[#878787]/20 "
            >
              <span className="text-sm text-[#969696] font-medium ">
                Mot de passe
              </span>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border-none rounded-xl outline-none h-6 bg-[#D4D4D4]/40 px-2"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-red-500 hover:bg-red-600 text-center transition-all ease duration-300 font-medium  h-14 text-white  rounded-xl cursor-pointer"
            >
              {loading ? <Loader /> : "Modifier"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateMotdepasse;
