import React from "react";
import { NavLink } from "react-router-dom";

const ProductPresentCard = ({ image, title, link, titlelink, image2 }) => {
  return (
    <div className="md:flex hidden md:flex-col h-full justify-center items-center gap-y-4 w-full bg-gray-100  rounded-lg px-4 min-h-[347px] ">
      {image ? (
        <img src={image} alt="product" className="w-36 h-36 object-contain" />
      ) : null}
      <span className="text-4xl text-center w-full font2  ">{title}</span>
      {image2 ? (
        <img src={image2} alt="product" className="w-36 h-36 object-contain" />
      ) : null}
      <NavLink to={link} className={"underline py-4"}>
        {" "}
        {titlelink}{" "}
      </NavLink>
    </div>
  );
};

export default ProductPresentCard;
