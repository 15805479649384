

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { tokenReturn } from '../../../atom'
import Loader from '../../Loader'
import { dateDuJour } from '../../utils'
import Layout from '../Layout'
import LigneTabServiceTraiteur from '../LigneTabServiceTraiteur'
import LignesTab from '../lignesTab'
    const DashboardHome = () => {



        const TitleTableau = {
            type : 1,
            numero : 'Numero',
            clients:"clients",
            date:"date",
            menu :"menu",
            revenue :"revenue",
            tel :"tel",
            statutCheck :true,
            lieu :"lieu",
            

        }
        
        
       

        const {
            data: commandeAdminside,
            isPending: loadingCommandeAdminside,
            isError: errorCommandeAdminside,
          } = useQuery({
            queryKey: ["CommandesAdminSide"],
            withCredentials: true,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/v1/auth/products/encours`,{ 
                headers : {
                  
                  "Access-Control-Allow-Origin": true,
                }
              }
              ),
            
          });

          
        const {
          data: HistoriqueAdminside,
          isPending: loadingHistoriqueAdminside,
          isError: errorHistoriqueAdminside,
        } = useQuery({
          queryKey: ["HistoriqueAdminSide"],
          withCredentials: true,
          queryFn: () =>
            axios.get(
              `https://cmchickenbackend.onrender.com/api/v1/auth/receipts/users/receipts/all`,{ 
              headers : {
                
                "Access-Control-Allow-Origin": true,
              }
            }
            ),
          
        });




          const {
            data: totalTraiteur,
            isPending: loadingTotalTraiteur,
            isError: errorTotalTraiteur,
          } = useQuery({
            queryKey: ["totalTraiteur"],
            withCredentials: true,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/v1/sales/getTotalTraiteur`,{ 
                headers : {
                  
                  "Access-Control-Allow-Origin": true,
                }
              }
              ),
           
          });



          const {
            data: totalNumberCommandes,
            isPending: loadingTotalNumberCommandes,
            isError: errorTotalNumberCommandes,
          } = useQuery({
            queryKey: ["totalNumberCommandes"],
            withCredentials: true,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/v1/sales/getTotalCommands/today`,{ 
                headers : {
                  
                  "Access-Control-Allow-Origin": true,
                  
                }
              }
              ),
           
          });




          const {
            data: totalRevenueCommandes,
            isPending: loadingTotalRevenueCommandes,
            isError: errorTotalRevenueCommandes,
          } = useQuery({
            queryKey: ["TotalRevenueCommandes"],
            withCredentials: true,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/v1/sales/getTotal/today`,{ 
                headers : {
                  
                  "Access-Control-Allow-Origin": true,
                  
              Authorization :`Bearer ${tokenReturn()}`
                }
              }
              ),
           
          });



          const {
            data: totalivraisonCommandes,
            isPending: loadingTotalLivraisonCommandes,
            isError: errorTotalLivraisonCommandes,
          } = useQuery({
            queryKey: ["TotalLivraisonLivre"],
            withCredentials: true,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/v1/sales/getDelivered`,{ 
                headers : {
                  
                  "Access-Control-Allow-Origin": true,
                  
                  
              Authorization :`Bearer ${tokenReturn()}`
                }
              }
              ),
           
          });



          const {
            data: TraiteurAdminside,
            isPending: loadingTraiteurAdminside,
            isError: errorTraiteurAdminside,
          } = useQuery({
            queryKey: ["TraiteurAdminSide"],
            withCredentials: true,
            queryFn: () =>
              axios.get(
                `https://cmchickenbackend.onrender.com/api/public/reservation/all`,{ 
                headers : {
                  
                  "Access-Control-Allow-Origin": true,
                }
              }
              ),
           
          });



          console.log(totalRevenueCommandes?.data,8888888);
        
         

    

         


        const historiquedata = HistoriqueAdminside?.data?.filter((elem) => {
            return elem.delivered === true;
          });
   
      return (
        <Layout >

            <div className='w-full px-7  py-8 h-full justify-start  items-start  flex flex-col'>

                    <section className='w-full flex flex-col gap-y-2'>
                    <div className='flex gap-x-2 justify-start items-center text-md'>
                    <svg
  width={15}
  height={15}
  viewBox="0 0 19 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.63443 0.704421C7.49631 0.654196 7.34309 0.66728 7.21549 0.740196L0.376931 4.64794C0.221143 4.73696 0.125 4.90263 0.125 5.08206V5.46095C0.125 5.6712 0.256534 5.85899 0.454128 5.93084L11.2718 9.86454C11.4099 9.91476 11.5631 9.90168 11.6907 9.82876L18.6231 5.86743C18.7789 5.77841 18.875 5.61274 18.875 5.43331V5.14212C18.875 4.93186 18.7435 4.74407 18.5459 4.67222L7.63443 0.704421ZM18.875 8.88856C18.875 8.50465 18.4603 8.26397 18.1269 8.45444L12.0795 11.9101C11.8243 12.056 11.5178 12.0821 11.2416 11.9817L0.795872 8.18324C0.46978 8.06466 0.125 8.30615 0.125 8.65314V10.6693C0.125 10.8796 0.256534 11.0673 0.454128 11.1392L11.2718 15.0729C11.4099 15.1231 11.5631 15.11 11.6907 15.0371L18.6231 11.0758C18.7789 10.9868 18.875 10.8211 18.875 10.6417V8.88856ZM0.454129 16.3699C0.256534 16.2981 0.125 16.1103 0.125 15.9V13.8615C0.125 13.5145 0.469779 13.273 0.795871 13.3916L11.2416 17.19C11.5178 17.2905 11.8243 17.2643 12.0795 17.1185L18.1269 13.6628C18.4603 13.4723 18.875 13.713 18.875 14.0969V15.9601C18.875 16.1395 18.7789 16.3052 18.6231 16.3942L11.7845 20.3019C11.6569 20.3749 11.5037 20.3879 11.3656 20.3377L0.454129 16.3699Z"
    fill="#111111"
  />
                    </svg>
                    <span >Cm-chicken {">"}  </span> 
                    <span >Commandes</span>
                    </div>
                    <div className='flex  '>
                            <h3 className='text-xl font-bold'> Dashboard </h3>
                    </div>
                    </section>

                    <section className='py-8 flex gap-x-5 w-full justify-start items-start  '>

                        <div className='w-full flex rounded-lg h-32 shadow-lg overflow-hidden bg-orange-400 text-white flex-col p-3 gap-y-5'>

                            <div className='flex flex-col w-full justify-start items-start'>
                                <h3 className='text-sm '>  Revenue Journalier  </h3>
                                <span className='text-xs '> {dateDuJour}  </span>
                            </div>
                            {loadingTotalRevenueCommandes ? (
  <div className="w-full h-full flex flex-col min-h-[250px] justify-center">
    <Loader />
  </div>
) : errorTotalRevenueCommandes ? (
  <div className="w-full flex h-[450px] justify-center text-gray-400 items-center">
    <p className="font-bold text-sm">
      Erreur 
    </p>
  </div>
) : (
  <div className="w-full flex justify-start items-start flex-col">
    <strong className="text-4xl 2xl:text-5xl gap-x-2 text-bold">
      {totalRevenueCommandes?.data} 
      <span className='text-lg'> Fcfa</span>
    </strong>
  </div>
)}

                        </div>

                        <div className='w-full flex h-32  rounded-lg shadow-lg justify-between overflow-hidden bg-white text-black flex-col p-3 gap-y-5'>

                            <div className='flex flex-col w-full justify-start items-start'>
                                <h3 className='text-lg  font-bold '> Commandes du jour </h3>
                            </div>

                            {loadingTotalNumberCommandes ? (
  <div className="w-full h-full flex flex-col min-h-[250px] justify-center">
    <Loader />
  </div>
) : errorTotalNumberCommandes ? (
  <div className="w-full flex h-[450px] justify-center text-gray-400 items-center">
    <p className="font-bold text-sm">
      Erreur 
    </p>
  </div>
) : (
  <div className="w-full flex justify-start items-start flex-col">
    <strong className="text-4xl 2xl:text-5xl text-bold">
      {totalNumberCommandes?.data}
    </strong>
  </div>
)}

                   
                        </div>


                                <div className='w-full  h-32  flex rounded-lg shadow-lg justify-between overflow-hidden bg-white text-black flex-col p-3 gap-y-5'>

                                <div className='flex flex-col w-full justify-start items-start'>
                                <h3 className='text-lg  font-bold '> En cours</h3>
                                </div>

                                <strong className='text-4xl  2xl:text-5xl text-bold'>
{loadingTotalLivraisonCommandes ? (
  <div className="w-full h-full flex flex-col min-h-[250px] justify-center">
    <Loader />
  </div>
) : errorTotalLivraisonCommandes ? (
  <div className="w-full flex h-[450px] justify-center text-gray-400 items-center">
    <p className="font-bold text-sm">
      Erreur 
    </p>
  </div>
) : (
  <div className="w-full flex justify-start items-start flex-col">
    <strong className="text-4xl 2xl:text-5xl text-bold">
      {commandeAdminside?.data.length}
    </strong>
  </div>
)}
  </strong> 
                             
                                </div>

                                <div className='w-full h-32  flex rounded-lg shadow-lg justify-between overflow-hidden bg-white text-black flex-col p-3 gap-y-5'>

<div className='flex flex-col w-full justify-start items-start'>
<h3 className='text-lg  font-bold '>Livré</h3>
</div>

<strong className='text-4xl  2xl:text-5xl text-bold'>
{loadingTotalLivraisonCommandes ? (
  <div className="w-full h-full flex flex-col min-h-[250px] justify-center">
    <Loader />
  </div>
) : errorTotalLivraisonCommandes ? (
  <div className="w-full flex h-[450px] justify-center text-gray-400 items-center">
    <p className="font-bold text-sm">
      Erreur 
    </p>
  </div>
) : (
  <div className="w-full flex justify-start items-start flex-col">
    <strong className="text-4xl 2xl:text-5xl text-bold">
      {totalivraisonCommandes?.data}
    </strong>
  </div>
)}
  </strong> 
                              </div>

                            <div className='w-full flex rounded-lg shadow-lg h-32  justify-between overflow-hidden bg-white text-black flex-col p-3 gap-y-5'>

                            <div className='flex flex-col w-full justify-start items-start'>
                            <h3 className='text-lg  font-bold '>Traiteur</h3>
                            </div>


                            {loadingTotalTraiteur ? (
                              <div className="w-full h-full flex flex-col min-h-[250px] justify-center">
                                <Loader />
                              </div>
                            ) : errorTotalTraiteur ? (
                              <div className="w-full flex h-[450px] justify-center text-gray-400 items-center">
                                <p className="font-bold text-sm">
                                  Erreur 
                                </p>
                              </div>
                            ) : (
                              <div className="w-full flex justify-start items-start flex-col">
                                <strong className="text-4xl 2xl:text-5xl text-bold">
                                  {totalTraiteur.data}
                                </strong>
                              </div>
                            )}


                            </div>

                    </section>

                    <section className='flex gap-x-6  w-full'>

                        <div className='flex-col flex  justify-start items-start w-1/2  gap-y-4'>

                            
                            <div className='w-full flex border bg-white h-[350px] shadow-lg p-2 rounded-lg overflow-hidden  flex-col justify-start items-start '>

                            <div className='w-full flex  p-4 justify-between items-center'>
       <h4> Commandes </h4>

       <NavLink to="/dashboardCommande" className="text-sm flex gap-x-1 justify-start items-center"> 
               <span> Voir plus </span>
               <span> <svg
width={38}
height={38}
viewBox="0 0 28 28"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M16.8398 9.66976L17.5643 9.47564L17.3701 8.7512L16.6457 8.94531L16.8398 9.66976ZM11.1903 17.955C10.9832 18.3137 11.1061 18.7724 11.4648 18.9795C11.8235 19.1866 12.2822 19.0637 12.4893 18.705L11.1903 17.955ZM18.7843 14.0291L17.5643 9.47564L16.1154 9.86387L17.3355 14.4173L18.7843 14.0291ZM16.6457 8.94531L12.0923 10.1654L12.4805 11.6143L17.0339 10.3942L16.6457 8.94531ZM16.1903 9.29476L11.1903 17.955L12.4893 18.705L17.4893 10.0448L16.1903 9.29476Z"
fill="#5D5D5D"
/>
</svg>
</span>
        </NavLink>
                            </div>     

                                 <div className='w-full   flex flex-col  overflow-auto justify-start items-start'>

                                 <section className='tableau w-full     flex justify-start items-start flex-col'>
                    
                    <LignesTab  {...TitleTableau} />
                      <div className='w-full'>
                   {loadingCommandeAdminside  ? 
                     <div className='w-full h-full flex flex-col min-h-[250px]  justify-center '>
                 
                    <Loader/>
                   </div>
                   : errorCommandeAdminside ? (
                    <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
                      <p className="font-bold text-sm  ">
                        Erreur lors de la récupération des données
                      </p>
                    </div>
                  ) :
                    <div className='w-full flex justify-start items-start  flex-col'>

                    {commandeAdminside?.data?.length > 0 ? ( commandeAdminside?.data?.map((item)=>{
                      return(
                          <LignesTab key={item.id}  {...item} />
                      )
                    })) : (
                      <div className="w-full  text-gray-400 bg-red-500 flex justify-center items-center">
                        <p className="font-bold text-xl ">Aucune commande trouvee </p>
                      </div>
                    )}
                 
                   </div>
                   }

                     
               
                 
                    </div>
                    </section>
                                 </div>    



                            </div>

                            <div className='w-full flex border bg-white h-[350px] shadow-lg p-2 rounded-lg overflow-hidden  flex-col justify-start items-start '>

                            <div className='w-full flex  p-4 justify-between items-center'>
       <h4> Historiques </h4>

       <NavLink  to="/commandeHistorique" className="text-sm flex gap-x-1 justify-start items-center"> 
               <span> Voir plus </span>
               <span> <svg
width={38}
height={38}
viewBox="0 0 28 28"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M16.8398 9.66976L17.5643 9.47564L17.3701 8.7512L16.6457 8.94531L16.8398 9.66976ZM11.1903 17.955C10.9832 18.3137 11.1061 18.7724 11.4648 18.9795C11.8235 19.1866 12.2822 19.0637 12.4893 18.705L11.1903 17.955ZM18.7843 14.0291L17.5643 9.47564L16.1154 9.86387L17.3355 14.4173L18.7843 14.0291ZM16.6457 8.94531L12.0923 10.1654L12.4805 11.6143L17.0339 10.3942L16.6457 8.94531ZM16.1903 9.29476L11.1903 17.955L12.4893 18.705L17.4893 10.0448L16.1903 9.29476Z"
fill="#5D5D5D"
/>
</svg>
</span>
        </NavLink>
</div>     

                       
<div className='w-full   h-full flex flex-col  overflow-auto justify-start items-start'>
<section className='tableau w-full h-full overflow-auto flex justify-start items-start flex-col'>
                    
                    <LignesTab  {...TitleTableau} />
                      <div className='w-full'>
                   {loadingHistoriqueAdminside  ? 
                     <div className='w-full h-full flex flex-col min-h-[250px]  justify-center '>
                 
                    <Loader/>
                   </div>
                   : errorHistoriqueAdminside ? (
                    <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
                      <p className="font-bold text-sm  ">
                        Erreur lors de la récupération des données
                      </p>
                    </div>
                  ) :
                    <div className='w-full flex justify-start items-start  flex-col'>

                    {historiquedata?.length > 0 ? ( historiquedata?.map((item)=>{
                      return(
                          <LignesTab key={item.id}  {...item} />
                      )
                    })) : (
                      <div className="w-full h-[240px] text-gray-400 flex justify-center items-center">
                        <p className="font-bold text-xl ">Aucune commande trouvee </p>
                      </div>
                    )}
                 
                   </div>
                   }

                     
               
                 
                    </div>
                    </section>
   </div>    




                            </div>

                        </div>

                        <div className='flex-col flex border  overflow-auto bg-white justify-start items-start w-1/2  rounded-lg h-[710px]  shadow-lg gap-y-4'>

                        <div className='w-full flex  p-4 justify-between items-center'>
       <h4> Service traiteur </h4>

       <NavLink to="/dashboardServiceTraiteur" className="text-sm flex gap-x-1 justify-start items-center"> 
               <span> Voir plus </span>
               <span> <svg
width={38}
height={38}
viewBox="0 0 28 28"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M16.8398 9.66976L17.5643 9.47564L17.3701 8.7512L16.6457 8.94531L16.8398 9.66976ZM11.1903 17.955C10.9832 18.3137 11.1061 18.7724 11.4648 18.9795C11.8235 19.1866 12.2822 19.0637 12.4893 18.705L11.1903 17.955ZM18.7843 14.0291L17.5643 9.47564L16.1154 9.86387L17.3355 14.4173L18.7843 14.0291ZM16.6457 8.94531L12.0923 10.1654L12.4805 11.6143L17.0339 10.3942L16.6457 8.94531ZM16.1903 9.29476L11.1903 17.955L12.4893 18.705L17.4893 10.0448L16.1903 9.29476Z"
fill="#5D5D5D"
/>
</svg>
</span>
        </NavLink>
                            </div>     

                       
<div className='w-full   h-full flex overflow-auto  flex-col justify-start items-start'>

<section className='tableau w-full  flex  justify-start items-start flex-col'>
                    
                    <LigneTabServiceTraiteur  {...TitleTableau} />
                      <div className='w-full'>
                   {loadingTraiteurAdminside  ? 
                     <div className='w-full h-full flex flex-col min-h-[250px]  justify-center '>
                 
                    <Loader/>
                   </div>
                   : errorTraiteurAdminside ? (
                    <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
                      <p className="font-bold text-sm  ">
                        Erreur lors de la récupération des données
                      </p>
                    </div>
                  ) :
                    <div className='w-full flex justify-start items-start  flex-col'>

                    {TraiteurAdminside.data?.length > 0 ? ( TraiteurAdminside.data?.map((item)=>{
                      return(
                          <LigneTabServiceTraiteur key={item.id}  {...item} />
                      )
                    })) : (
                      <div className="w-full h-[240px] text-gray-400 flex justify-center items-center">
                        <p className="font-bold text-xl ">Aucune commande trouvee </p>
                      </div>
                    )}
                 
                   </div>
                   }

                     
               
                 
                    </div>
                    </section>
   
   </div>    


                        </div>



                    </section>

                   

            </div>
          
        </Layout>
      )
    }
    
    export default DashboardHome
    