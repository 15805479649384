import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { tokenReturn } from "../atom";
import Layout from "./Layout";
import Loader from "./Loader";
import HistoriqueCardMobile from "./historiqueCardMobile";
import HistoryCard from "./historyCard";
import firstimg from "./images/firstimg.png";
import poulet from "./images/poulet.png";
import ProductHistoriqueCard from "./productHistoriqueCard";
import UpdateMotdepasse from "./updateMotdepasse";

const HistoriquePage = () => {
  const {
    data: HistoryData,
    isPending: loadingHistory,
    isError: errorHistoryData,
  } = useQuery({
    queryKey: ["historyData5"],
    withCredentials: true,
    queryFn: () =>
      axios.get(
        `https://cmchickenbackend.onrender.com/api/v1/auth/products/all`,
        {
          headers: {
            Authorization: `Bearer ${tokenReturn()}`,
          },
        }
      ),
    onError: (error) => {
      toast.error("une erreur est survenue");
    },
  });


    const {
      data: HistoryDataToday,
      isPending: loadingHistoryToday,
      isError: errorHistoryAllToday,
   
    } = useQuery({
      queryKey: ["historyDataToday"],
      withCredentials: true,
      refetchInterval: 200,
      queryFn: () =>
        axios.get(
          `https://cmchickenbackend.onrender.com/api/v1/auth/products/today`,
          {
            headers: {
              Authorization: `Bearer ${tokenReturn()}`,
            },
          }
        ),
      onError: (error) => {
        toast.error("une erreur est survenue");
      },
    });
  
 


  const [totalOrderDay, setTotalOrrderDay] = useState(0);
  const [dateOfDay, setDateOfDate] = useState(0);
  const [NumberOfProduct, setNumberOfProduct] = useState(0);

  const getNumberArticleOfDday = () => {
    setNumberOfProduct(HistoryDataToday?.data.length);
  };

  const GetdateOfDday = () => {
    if (HistoryDataToday?.data && HistoryDataToday?.data.length > 0) {
      setDateOfDate(HistoryDataToday?.data[0]?.addedDate.slice(0, 10));
    }
  };

  const totalcommandeduJour = useCallback(() => {
    if(HistoryDataToday?.data.length > 0){ 
    const total = HistoryDataToday?.data.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    setTotalOrrderDay(total);
  }
  }, [HistoryDataToday?.data]);

  const getInfoDateAujourProduct = (dateItem) => {
    const dateDujour = dateItem.slice(0, 10);
    const annee = dateDujour.slice(0, 4);
    const mois = dateDujour.slice(5, 7);
    const jour = dateDujour.slice(8, 10);

    return { annee, mois, jour };
  };

  useEffect(() => {
    totalcommandeduJour();
    GetdateOfDday();
    getNumberArticleOfDday();
  }, [totalcommandeduJour, loadingHistoryToday?.data]);

  if(errorHistoryAllToday  ){

    return <div className="w-full min-h-screen flex flex-col justify-center items-center">Une erreur est survenue </div>
    
  }


  return (
    <Layout>
      <div className="flex flex-col py-4 px-4 lg:px-36 max-w-[1450px]  w-full min-h-screen  gap-y-4">
        <div className=" md:flex hidden  flex-col w-full justify-start py-8 gap-y-4">
          <h2 className="text-3xl sm:px-0 px-3 text-[#DA404A] font-bold ">
            Bienvenue sur votre compte client
          </h2>
          <div className="w-full flex flex-col ">
            <div className="w-full flex justify-start gap-y-4 items-end  bg-white  rounded-2xl ">
              <div className="flex flex-col w-9/12 gap-y-3 pl-4  ">
                <div className="h-[500px]  flex flex-col w-full ">
                  <span className="  text-[#DA404A] text-2xl font-bold ">
                    Commandes d'aujourd'hui
                  </span>
                  <div className="relative scrolldiv3 py-4 overflow-hidden flex flex-col gap-y-4 gap-x-4 rounded-2xl w-full  overflow-x-auto">
                    <div className="w-[280px]  h-full flex gap-x-4">
                    {loadingHistoryToday ? (
  <div className="flex  w-full h-[450px] justify-center items-center">
    <Loader />
  </div>
) 
// : errorHistoryAllToday ? (
//   <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
//     <p className="font-bold text-sm  ">
//       Erreur lors de la récupération des données
//     </p>
//   </div>
// ) 
: Array.isArray(HistoryDataToday?.data) && HistoryDataToday?.data?.length > 0 ? (
  HistoryDataToday?.data?.map((item) => (
    <ProductHistoriqueCard
      key={item.id}
      description={item.description}
      quantite={item.quantity}
      title={item.name}
      price={item.price}
      imagesection={poulet}
      image={firstimg}
    />
  ))
) : (
  <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
    <p className="font-bold text-xl ">Aucune commandes</p>
  </div>
)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col rounded-2xl  gap-y-2 justify-center items-center  h-[520px] flex-shrink-0 w-[350px] bg-red-500 ">
                <h4 className="py-1 px-4 text-sm font-bold rounded-full bg-white text-red-500 ">
                  Net a payer{" "}
                </h4>
                <h3 className="text-5xl text-white fontp font-extrabold py-4">
                  {totalOrderDay} XAF
                </h3>
                <h4 className="py-1 px-4 text-sm font-bold rounded-full bg-white text-red-500 ">
                  Total articles
                </h4>
                <h3 className="text-2xl text-white  font-extrabold py-4">
                  {NumberOfProduct}
                </h3>

                <h4 className="py-1 px-4 text-sm font-bold rounded-full bg-white text-red-500 ">
                  Date
                </h4>
                <h3 className="text-xl text-white  font-extrabold py-4">
                  {dateOfDay}
                </h3>
                <h4 className="py-1 px-4 text-sm font-bold rounded-full bg-white text-red-500 ">
                  statut
                </h4>
                <h3 className="text-xl text-white  font-extrabold py-4">
                  Payé aujourd'hui
                </h3>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-3xl sm:hidden flex font-bold text-red-500">
          Dashboard
        </h2>
        <HistoriqueCardMobile />
        <div className="flex  gap-y-4 gap-x-4  rounded-xl  w-full justify-start items-start">
          <div className="flex py-8 px-4 h-[600px] bg-white  rounded-2xl   flex-col gap-y-4 w-full xl:w-8/12 ">
            <h2 className="text-xl sm:text-3xl sm:px-0 px-3 text-[#DA404A] font-bold ">
              {" "}
              Historique de commande{" "}
            </h2>
            <div className="flex w-full justify-start items-center">
              {/* <button className="bg-red-500 sm:text-lg text-sm flex gap-x-4 justify-center items-center px-4 py-2 text-white rounded-xl">
                Trier (Hier)
                <div
                  className="sm:h-8 sm:w-8 h-4 w-4 flex rounded-full justify-center 
                items-center  bg-white"
                >
                  <ChevronDown color="red" />
                </div>
              </button> */}
            </div>
            <div className="flex  px-3 scrolldiv4 flex-col  w-full overflow-x-hidden   gap-y-3 justify-start items-center">
              {loadingHistory ? (
                <div className="flex  w-full h-[450px] justify-center items-center">
                  <Loader />
                </div>
              ) : errorHistoryData ? (
                <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
                  <p className="font-bold text-sm  ">
                    Erreur lors de la récupération des données
                  </p>
                </div>
              ) : HistoryData?.data.length > 0 ? (
                HistoryData?.data.map((item) => (
                  <HistoryCard
                    key={"4" + item.id}
                    dateItem={getInfoDateAujourProduct(item?.addedDate)}
                    description={item.description}
                    image={poulet}
                    price={item.price}
                    title={item.name}
                  />
                ))
              ) : (
                <div className="w-full h-[240px] text-gray-400 flex justify-center items-center">
                  <p className="font-bold text-xl ">Aucun produit disponible</p>
                </div>
              )}
            </div>
          </div>
          <UpdateMotdepasse />
        </div>
      </div>
    </Layout>
  );
};

export default HistoriquePage;
