

    import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { ChevronDownIcon } from 'lucide-react';
import React, { Fragment, useState } from 'react';
import { toast } from 'sonner';
import { tokenReturn } from '../../atom';
    
    const LignesTab = React.forwardRef((propsLignesTabs,forwardRef ) => {
        const [isOpen, setIsOpen] = useState(false);
        // const [isOpen1, setIsOpen1] = useState(false);
          const [loading,setLoading] = useState(false)
       const  propsLignesTab = {...propsLignesTabs}

        propsLignesTab.numero = propsLignesTab.id
        propsLignesTab.date = propsLignesTab.date.slice(0,10)

       
        const prixTotal = propsLignesTab?.purchasedObjects?.reduce((acc,item)=>{
          return acc + item.price
      },0)


      
       

            const showDropdown  = () =>{
                if(isOpen){
                    setIsOpen(false)
                }else{
                    setIsOpen(true)
                }
            }

            const ValiderLivraison = async () =>{
                try {
                  setLoading(true);
                  await axios
                    .put(
                      `https://cmchickenbackend.onrender.com/api/v1/auth/product/${propsLignesTab.id}/setDelivered`,
                  
                      {
                        headers: {
                          "Access-Control-Allow-Origin": true,
                          
                           Authorization: `Bearer ${tokenReturn()}`,
                        },
                      }
                    )
                    .then((res) => {
                  
                      setInterval(() => {
                        setLoading(false);
                      }, 1000);
                      toast.success("Vous êtes connecté !");
                    })
                    .catch((err) => {
                      toast.error("Veuillez rentrer les bons identifiants");
                      setInterval(() => {
                        setLoading(false);
                      }, 1000);
                    })
                    .finally(() => {});
                } catch (err) {
                  console.log(err, 88);
                }
              } 

            

           
               
      return (
        <div 
  ref={forwardRef} className={`w-full flex border-b h-12  overflow-x-auto min-w-[1250px] gap-x-4  pl-2  border-[#D1D1D1] ${propsLignesTab.type === 1  ? "text-white bg-red-500"  : "text-black bg-white"} `}>
            <div className='w-2/12 overflow-hidden flex justify-start items-center'>

              {propsLignesTab.type === 1 ?  

<span className='text-sm capitalize  '> # </span>
              :   

              <span className='text-sm capitalize  '> {propsLignesTab.id} </span>
              }
            </div>
            <div className='w-3/12 overflow-hidden flex justify-start items-center'>
                <span className='text-sm capitalize  '> {propsLignesTab.date} </span>
            </div>
          
              <div className='w-3/12 overflow-hidden flex justify-start items-center'>
              {propsLignesTab.type === 1 ? 
               <span className='text-sm w-full capitalize '> 
                
               Clients
               
               
                </span>
               : 
               <span className='text-sm capitalize  truncate'> {propsLignesTab.user_name} </span>
           
              }
            </div>

            <div className='w-3/12 flex overflow-hidden justify-start items-center'>
               
               {propsLignesTab.type === 1 ? 
               <span className='text-sm w-full capitalize '> 
                
               {propsLignesTab.menu}
               
               
                </span>
               : 
               <Popover>
               {({ open }) => (
                 /* Use the `open` state to conditionally change the direction of the chevron icon. */
                 <>
                 <div className='w-full flex '>
                   <Popover.Button className='text-sm flex px-4 justify-between items-center capitalize ' >
                    <span>Details </span>
                     <ChevronDownIcon onClick={()=>showDropdown()} className={open ? 'rotate-180 transform' : ''} />
                   </Popover.Button>
                   </div>
                   <Transition
                   appear show={isOpen} 
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              
            >
                   <Popover.Panel className="fixed flex flex-col bg-white  w-72 h-auto p-2 shadow-lg gap-y-2">
                    {
propsLignesTab.purchasedObjects?.map((item)=>{
  return(
    <li key={item.id} className='text-sm'> {item.name} </li>
  )
})}
                   
                   
                   </Popover.Panel>
                   </Transition>
                 </>
               )}
             </Popover>
               
               }
                

        
            </div>


            <div className='w-2/12 flex  overflow-hidden justify-between items-center'>

            {propsLignesTab.type === 1 ? 
               <div className='text-sm w-full flex justify-between capitalize '> 
                
              <span>Revenue</span> 
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16699 5.8335H10.8337" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
            <path d="M4.16699 10H9.16699" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
            <path d="M4.16699 14.167H7.50033" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
            <path d="M15.833 15L18.333 12.5M15.833 15L13.333 12.5M15.833 15L15.833 5" stroke="white" strokeWidth="1.25"/>
            </svg>
               
                </div>
               : 
                <div className='flex justify-start items-center'>
                  
               <span className='text-sm capitalize '> {prixTotal} <b className='text-red-500 text-sm'>Xaf</b> </span>

            </div>

    }
       </div>

            <div className='w-2/12 overflow-hidden flex justify-start items-center'>
            {propsLignesTab.type === 1 ? 
                <span className='text-sm capitalize '> Tel </span> :
                  <span className='text-sm capitalize '> {propsLignesTab.phone_number } </span>
                }
            </div>
            <div className='w-3/12 flex justify-between items-center'>
            {propsLignesTab.type === 1 ? 
            <div className='w-full flex justify-between items-center'>
                <span className='text-sm'> lieu </span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.16699 14.1665H10.8337" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
<path d="M4.16699 10H9.16699" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
<path d="M4.16699 5.83301H7.50033" stroke="white" strokeWidth="1.25" strokeLinecap="round"/>
<path d="M15.833 5L18.333 7.5M15.833 5L13.333 7.5M15.833 5L15.833 15" stroke="white" strokeWidth="1.25"/>
</svg> </div> : 

                
<span className='text-sm '> {propsLignesTab.location} </span>

            }
            </div>

            <div className='w-2/12 flex overflow-hidden justify-start items-center'>
            { propsLignesTab.type !== 1  ? 
                <div className=' capitalize '> 
                {  propsLignesTab.delivered === true ?  
                    <span className='flex gap-x-2'>   
                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="4" fill="#F93A47"/>
<path d="M5.33333 11.3333L8 13.3333L14 6" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
</svg>

                    <span className='text-[10px]'> Livré </span>
                    </span>
                :   
                    <button onClick={()=>ValiderLivraison()} className='p-2 text-white text-xs bg-red-500  text-center rounded-md'> 
                      {
                        loading ? "encours.." 
                      :
                      
                      <span> valider </span>
                      
                      }

                    
                    </button>

                } </div>  : 
                <span> statut  </span> }
            </div>
         
            {/* <div className={`w-1/12  ${propsLignesTab.type !== 1  && "bg-custom-white" } flex justify-center items-center`}>
                <span className='text-sm capitalize '>
                {propsLignesTab.type === 1 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9.99967" cy="9.99984" r="0.833333" transform="rotate(90 9.99967 9.99984)" stroke="white" strokeWidth="1.66667" strokeLinecap="round"/>
<circle cx="9.99967" cy="4.99984" r="0.833333" transform="rotate(90 9.99967 4.99984)" stroke="white" strokeWidth="1.66667" strokeLinecap="round"/>
<circle cx="9.99967" cy="14.9998" r="0.833333" transform="rotate(90 9.99967 14.9998)" stroke="white" strokeWidth="1.66667" strokeLinecap="round"/>
</svg> : 
   <Popover>
  
    <>
     <div className='w-full flex '>
       <Popover.Button className='text-sm relative flex justify-between items-center capitalize ' >
       <button onClick={()=>CloseDeleteBlock()} >

    
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33301 12.5L8.33301 10" stroke="#3D3D3D" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M11.667 12.5L11.667 10" stroke="#3D3D3D" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M2.5 5.8335H17.5V5.8335C16.7234 5.8335 16.3351 5.8335 16.0289 5.96036C15.6205 6.12952 15.296 6.45398 15.1269 6.86236C15 7.16864 15 7.55693 15 8.3335V13.3335C15 14.9048 15 15.6905 14.5118 16.1787C14.0237 16.6668 13.238 16.6668 11.6667 16.6668H8.33333C6.76198 16.6668 5.97631 16.6668 5.48816 16.1787C5 15.6905 5 14.9048 5 13.3335V8.33349C5 7.55693 5 7.16864 4.87313 6.86236C4.70398 6.45398 4.37952 6.12952 3.97114 5.96036C3.66485 5.8335 3.27657 5.8335 2.5 5.8335V5.8335Z" stroke="#3D3D3D" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M8.39045 2.80883C8.48541 2.72023 8.69465 2.64194 8.98572 2.5861C9.2768 2.53027 9.63343 2.5 10.0003 2.5C10.3672 2.5 10.7239 2.53027 11.0149 2.5861C11.306 2.64194 11.5152 2.72023 11.6102 2.80883" stroke="#3D3D3D" strokeWidth="1.5" strokeLinecap="round"/>
</svg>
</button>
       </Popover.Button>
       </div>
       <Transition
       appear show={isOpen1} 
  as={Fragment}
  enter="transition ease-out duration-200"
  enterFrom="opacity-0 translate-y-1"
  enterTo="opacity-100 translate-y-0"
  leave="transition ease-in duration-150"
  leaveFrom="opacity-100 translate-y-0"
  leaveTo="opacity-0 translate-y-1"
  
>
       <Popover.Panel className="fixed flex text-sm z-10 -translate-x-[15.48rem] justify-start items-center  translate-y-3 h-12   bg-white  w-72   shadow-lg ">
        <span className='w-1/2 px-2 font-medium'> supprimer ? </span>
        <button   className='flex w-1/4  hover:text-white hover:bg-green-400 justify-center items-center bg-custom-white px-2 border-r  h-full  gap-x-2'>
        <svg width="20" height="20" className='text-red-500' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.16667 11.6667L6.86091 13.6873C7.21815 13.9553 7.7231 13.894 8.00587 13.5484L15 5" stroke="#111111" strokeWidth="1.66667" strokeLinecap="round"/>
</svg>

            <span> Oui </span></button>
            <button onClick={()=>setIsOpen1(false)} className='w-1/4 hover:bg-red-500 hover:text-white bg-custom-white px-2 h-full flex justify-center items-center  gap-x-2'> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 5L5 15" stroke="#111111" strokeWidth="1.66667" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M5 5L15 15" stroke="#111111" strokeWidth="1.66667" strokeLinecap="square" strokeLinejoin="round"/>
</svg>
 <span> Non </span> </button>
       </Popover.Panel>
       </Transition>
     </>

 </Popover>

 }
                     
 </span>
            </div> */}
        </div>
      )
    })
    
    export default LignesTab
    