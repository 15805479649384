import React from "react";

const Cardcomplements = ({ image, selected, price, texte }) => {
  const checkselected = selected
    ? "bg-[#DA404A] text-white hover:text-white"
    : "bg-[#F3F3F3] text-gray-900";
  return (
    <div
      className={` flex-col min-w-[117px] cursor-pointer ${checkselected}  transition-all ease duration-400 hover:bg-[#DA404A] 
      overflow-hidden justify-center items-center rounded-xl  `}
    >
      <div className="w-full bg-white h-16">
        {image ? (
          <img src={image} alt={texte} className="object-cover h-full w-full" />
        ) : null}
      </div>
      <div className=" text-sm px-4 gap-x-4 w-full text-center capitalize h-8 flex justify-start items-center">
        <span className="text-center w-full">{texte}</span>
      </div>
    </div>
  );
};

export default Cardcomplements;
