

export  const imagesTab =   [
    {
       id: "1",
       url: "image-1.png",
       title: "beach"  
    },
    {
        id: "2",
        url: "image-2.jpg",
        title: "boat"  
     },
     {
        id: "3",
        url: "image-3.jpg",
        title: "forest"  
     },
     {
        id: "4",
        url: "image-4.png",
        title: "city"  
     },
     {
        id: "5",
        url: "image-5.png",
        title: "Italy"  
     }


]