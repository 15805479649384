

        import { Disclosure } from '@headlessui/react';
import Cookies from 'js-cookie';
import { ChevronUpIcon } from 'lucide-react';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import logo from '../../components/images/logo.png';
import LinkSideBarDashboard from './LinkSideBarDashboard';
        const SideBarDashboard = () => {

          const navigate = useNavigate();
          const seDeconnecter = () =>{
            Cookies.remove("token77xAplD");
        toast("vous etes deconnecté", { type: "success" });
            navigate("/");
          }

          return (
            <div className='flex h-full flex-col  w-full'>
                <div className="flex flex-col h-full w-full" >
                <div className='w-full justify-start  p-7 items-center  flex gap-x-3 '>
                    <img src={logo} alt="logo" className='h-14 w-14 object-cover' />
                    <NavLink to="/" className='text-red-500 text-lg font-bold'><span translate={"no"}>CM CHICKEN </span> </NavLink>
                </div>

                <div className='flex py-10  px-7 flex-col gap-y-4'>
                    <LinkSideBarDashboard title={"Dashboard"} link={"/dashboardHome"} 
                    icon={<svg
  width={25}
  height={25}
  viewBox="0 0 19 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.63443 0.704421C7.49631 0.654196 7.34309 0.66728 7.21549 0.740196L0.376931 4.64794C0.221143 4.73696 0.125 4.90263 0.125 5.08206V5.46095C0.125 5.6712 0.256534 5.85899 0.454128 5.93084L11.2718 9.86454C11.4099 9.91476 11.5631 9.90168 11.6907 9.82876L18.6231 5.86743C18.7789 5.77841 18.875 5.61274 18.875 5.43331V5.14212C18.875 4.93186 18.7435 4.74407 18.5459 4.67222L7.63443 0.704421ZM18.875 8.88856C18.875 8.50465 18.4603 8.26397 18.1269 8.45444L12.0795 11.9101C11.8243 12.056 11.5178 12.0821 11.2416 11.9817L0.795872 8.18324C0.46978 8.06466 0.125 8.30615 0.125 8.65314V10.6693C0.125 10.8796 0.256534 11.0673 0.454128 11.1392L11.2718 15.0729C11.4099 15.1231 11.5631 15.11 11.6907 15.0371L18.6231 11.0758C18.7789 10.9868 18.875 10.8211 18.875 10.6417V8.88856ZM0.454129 16.3699C0.256534 16.2981 0.125 16.1103 0.125 15.9V13.8615C0.125 13.5145 0.469779 13.273 0.795871 13.3916L11.2416 17.19C11.5178 17.2905 11.8243 17.2643 12.0795 17.1185L18.1269 13.6628C18.4603 13.4723 18.875 13.713 18.875 14.0969V15.9601C18.875 16.1395 18.7789 16.3052 18.6231 16.3942L11.7845 20.3019C11.6569 20.3749 11.5037 20.3879 11.3656 20.3377L0.454129 16.3699Z"
    fill="#111111"
  />
                    </svg>
                    } />
<div className='flex  flex-col  gap-y-4'>

<Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg  items-center  text-left text-sm font-medium   focus:outline-none focus-visible:ring">
        

                    <div    className='w-full text-custom-black font-bold justify-start items-center flex gap-x-2 h-auto px-5 py-3'>
                <span><svg width="25" height="25" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5.20801" y="4.6665" width="14.5833" height="17.7083" rx="2" stroke="#111111" stroke-width="1.5"/>
                    <path d="M9.375 9.875H15.625" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M9.375 14.0415H15.625" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M9.375 18.2085H13.5417" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    </svg></span>
                <span> Commandes </span>
                    </div>

                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 `}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4  text-sm text-gray-500">
              <div className='px-8 flex flex-col'>

<LinkSideBarDashboard title={"En cours"} link={"/dashboardCommande"} 
icon={<svg
  width={25}
  height={26}
  viewBox="0 0 25 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.1455 5.7085C17.6085 5.7085 18.34 5.7085 18.8654 6.0596C19.0929 6.2116 19.2882 6.40691 19.4402 6.63439C19.7913 7.15986 19.7913 7.89135 19.7913 9.35433V19.2502C19.7913 21.2143 19.7913 22.1964 19.1811 22.8066C18.571 23.4168 17.5889 23.4168 15.6247 23.4168H9.37467C7.41049 23.4168 6.4284 23.4168 5.8182 22.8066C5.20801 22.1964 5.20801 21.2143 5.20801 19.2502V9.35433C5.20801 7.89135 5.20801 7.15986 5.55911 6.63439C5.71111 6.40691 5.90642 6.2116 6.1339 6.0596C6.65937 5.7085 7.39086 5.7085 8.85384 5.7085"
    stroke="#111111"
    strokeWidth={2}
  />
  <path
    d="M9.375 5.70833C9.375 4.55774 10.3077 3.625 11.4583 3.625H13.5417C14.6923 3.625 15.625 4.55774 15.625 5.70833C15.625 6.85893 14.6923 7.79167 13.5417 7.79167H11.4583C10.3077 7.79167 9.375 6.85893 9.375 5.70833Z"
    stroke="#111111"
    strokeWidth={2}
  />
  <path
    d="M9.375 13L15.625 13"
    stroke="#111111"
    strokeWidth={2}
    strokeLinecap="round"
  />
  <path
    d="M9.375 17.1665L13.5417 17.1665"
    stroke="#111111"
    strokeWidth={2}
    strokeLinecap="round"
  />
</svg>


} />

<LinkSideBarDashboard title={"Historique"} link={"/commandeHistorique"} 
icon={<svg width="25" height="25" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="5.20801" y="4.6665" width="14.5833" height="17.7083" rx="2" stroke="#111111" stroke-width="1.5"/>
<path d="M9.375 9.875H15.625" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
<path d="M9.375 14.0415H15.625" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
<path d="M9.375 18.2085H13.5417" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
</svg>

} />
</div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

                    
                 
                    

</div>                  
<LinkSideBarDashboard title={"Menu"} link={"/"} 
                    icon={<svg width="25" height="25" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.33301 8.8335L8.33301 7.79183C8.33301 5.49065 10.1985 3.62516 12.4997 3.62516V3.62516C14.8009 3.62516 16.6663 5.49064 16.6663 7.79183L16.6663 8.8335" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71079 8.37729C3.125 8.96308 3.125 9.90589 3.125 11.7915V15.4165C3.125 19.1877 3.125 21.0734 4.29657 22.2449C5.46815 23.4165 7.35376 23.4165 11.125 23.4165H13.875C17.6462 23.4165 19.5319 23.4165 20.7034 22.2449C21.875 21.0734 21.875 19.1877 21.875 15.4165V11.7915C21.875 9.90589 21.875 8.96308 21.2892 8.37729C20.7034 7.7915 19.7606 7.7915 17.875 7.7915H7.125C5.23938 7.7915 4.29657 7.7915 3.71079 8.37729ZM10.375 12.9998C10.375 12.4476 9.92728 11.9998 9.375 11.9998C8.82272 11.9998 8.375 12.4476 8.375 12.9998V15.0832C8.375 15.6355 8.82272 16.0832 9.375 16.0832C9.92728 16.0832 10.375 15.6355 10.375 15.0832V12.9998ZM16.625 12.9998C16.625 12.4476 16.1773 11.9998 15.625 11.9998C15.0727 11.9998 14.625 12.4476 14.625 12.9998V15.0832C14.625 15.6355 15.0727 16.0832 15.625 16.0832C16.1773 16.0832 16.625 15.6355 16.625 15.0832V12.9998Z" fill="#111111"/>
                    </svg>
                    
                    
                    } />

                                        
<LinkSideBarDashboard title={"Traiteur"} link={"/dashboardServiceTraiteur"} 
                    icon={<svg width="25" height="25" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 21.5V3.5L15 5.5L12 3.5L9 5.5L6 3.5V21.5L9 20L12 21.5L15 20L18 21.5Z" stroke="#111111" stroke-width="1.5" stroke-linejoin="round"/>
                    <path d="M10 9.5H14" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M10 15.5H14" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M10 12.5H14" stroke="#111111" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    
                    
                    } />
                </div>

                <div className='flex py-10 px-7 border-t border-custom-gray flex-col gap-y-4'>
                    <LinkSideBarDashboard title={"Fast-food"} link={"/"} 
                    icon={<svg width="25" height="25" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16699 8.83317L3.63666 9.3635L3.10633 8.83317L3.63666 8.30284L4.16699 8.83317ZM9.37533 21.0415C8.96111 21.0415 8.62533 20.7057 8.62533 20.2915C8.62533 19.8773 8.96111 19.5415 9.37533 19.5415L9.37533 21.0415ZM8.84499 14.5718L3.63666 9.3635L4.69732 8.30284L9.90566 13.5112L8.84499 14.5718ZM3.63666 8.30284L8.84499 3.09451L9.90565 4.15517L4.69732 9.3635L3.63666 8.30284ZM4.16699 8.08317L15.1045 8.08317L15.1045 9.58317L4.16699 9.58317L4.16699 8.08317ZM15.1045 21.0415L9.37533 21.0415L9.37533 19.5415L15.1045 19.5415L15.1045 21.0415ZM21.5837 14.5623C21.5837 18.1407 18.6828 21.0415 15.1045 21.0415L15.1045 19.5415C17.8544 19.5415 20.0837 17.3123 20.0837 14.5623L21.5837 14.5623ZM15.1045 8.08317C18.6828 8.08317 21.5837 10.984 21.5837 14.5623L20.0837 14.5623C20.0837 11.8124 17.8544 9.58317 15.1045 9.58317L15.1045 8.08317Z" fill="#111111"/>
                    </svg>
                    
                    } />
<button     onClick={(e) => seDeconnecter()}  className='w-full text-custom-black font-bold justify-start items-center flex gap-x-2 h-auto px-5 py-3'>
                <span><svg width="25" height="25" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.08301 13.0001L1.49736 12.5316L1.12254 13.0001L1.49736 13.4686L2.08301 13.0001ZM11.458 13.7501C11.8722 13.7501 12.208 13.4143 12.208 13.0001C12.208 12.5859 11.8722 12.2501 11.458 12.2501V13.7501ZM5.66402 7.32323L1.49736 12.5316L2.66866 13.4686L6.83533 8.26027L5.66402 7.32323ZM1.49736 13.4686L5.66402 18.6769L6.83533 17.7399L2.66866 12.5316L1.49736 13.4686ZM2.08301 13.7501H11.458V12.2501H2.08301V13.7501Z" fill="#111111"/>
                    <path d="M10.417 8.97068V8.05509C10.417 6.43675 10.417 5.62758 10.891 5.06809C11.3649 4.5086 12.1631 4.37557 13.7594 4.10952L15.9306 3.74765C19.1737 3.20713 20.7952 2.93688 21.8561 3.83557C22.917 4.73427 22.917 6.37818 22.917 9.66602V16.3338C22.917 19.6217 22.917 21.2656 21.8561 22.1643C20.7952 23.063 19.1737 22.7927 15.9306 22.2522L13.7594 21.8903C12.1631 21.6243 11.3649 21.4912 10.891 20.9317C10.417 20.3723 10.417 19.5631 10.417 17.9447V17.2353" stroke="#111111" stroke-width="1.5"/>
                    </svg></span>
                <span> Deconnexion </span>
                    </button>
                </div>

                </div>
              
            </div>
          )
        }
        
        export default SideBarDashboard
        