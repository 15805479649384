import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { memo, useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { tokenReturn } from "../atom";
import Loader from "./Loader";
import firstimg from "./images/firstimg.png";
import poulet from "./images/poulet.png";
import ProductHistoriqueCard from "./productHistoriqueCard";
const HistoriqueCardMobile = () => {
  // const [loading, setLoading] = useState(false);
  // const [product, setProduct] = useState([]);

  const {
    data: HistoryDataToday,
    isPending: loadingHistoryToday,
    isError: errorHistoryAllToday,
  } = useQuery({
    queryKey: ["historyData"],
    withCredentials: true,
    queryFn: async () =>
     await axios.get(
        `https://cmchickenbackend.onrender.com/api/v1/auth/products/today`,
        {
          headers: {
            "Access-Control-Allow-Origin": true,

            Authorization: `Bearer ${tokenReturn()}`,
          },
        }
      ),
    onError: (error) => {
      toast.error("une erreur est survenue");
    },
  });



  const [totalOrderDay, setTotalOrrderDay] = useState(0);
  const [dateOfDay, setDateOfDate] = useState(0);
  const [NumberOfProduct, setNumberOfProduct] = useState(0);

  const GetdateOfDday = () => {
    if (HistoryDataToday?.data && HistoryDataToday?.data.length > 0) {
      setDateOfDate(HistoryDataToday?.data[0]?.addedDate.slice(0, 10));
    }
  };

  const getNumberArticleOfDday = () => {
    setNumberOfProduct(HistoryDataToday?.data.length);
  };

  const totalcommandeduJour = useCallback(() => {
    const total = HistoryDataToday?.data.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    setTotalOrrderDay(total);
  }, [HistoryDataToday?.data]);

  useEffect(() => {
    totalcommandeduJour();
    getNumberArticleOfDday();
    totalcommandeduJour();
    GetdateOfDday();
  }, [totalcommandeduJour, HistoryDataToday?.data]);

  return (
    <div className="flex md:hidden flex-col rounded-2xl bg-white  overflow-hidden border-2 border-red-500 w-full gap-y-4">
      <div className="w-full divide-x-2  divide-white text-white  bg-red-500 flex p-2 ">
        <div className="flex  w-full flex-col gap-y-2">
          <span> Net a payer </span>
          <div className="flex gap-x-2 justify-start items-center">
            <h4 className="text-2xl font-bold fontp text-center">
              {" "}
              {totalOrderDay}{" "}
            </h4>{" "}
            Xaf{" "}
          </div>
        </div>
        <div className="flex flex-col px-4  w-full gap-y-2">
          <span> Total articles </span>
          <div className="flex  flex-col gap-x-2 gap-y-2">
            <span className="font-bold"> {NumberOfProduct} </span>
            <span> Date </span>
            <h4 className="fontf font-bold  "> {dateOfDay} </h4>
          </div>
        </div>
      </div>
      <div className="flex   pl-4  flex-col gap-y-2">
        <h5 className="text-red-500 text-xl font-bold "> commande recente </h5>
        <div className="w-full pb-4 my-4 flex scrolldiv3  overflow-x-auto overflow-y-hidden gap-x-4">
          {loadingHistoryToday ? (
            <div className="flex w-[280px] h-full justify-center items-center">
              <Loader />
            </div>
          ) : errorHistoryAllToday ? (
            <div className="w-full flex h-[450px]  justify-center text-gray-400 items-center">
              <p className="font-bold text-sm  ">
                Erreur lors de la récupération des données
              </p>
            </div>
          ) : HistoryDataToday?.data.length > 0 ? (
            HistoryDataToday?.data?.map((item) => (
              <ProductHistoriqueCard
                key={item.id}
                description={item.description}
                quantite={item.quantity}
                title={item.name}
                price={item.price}
                imagesection={poulet}
                image={firstimg}
              />
            ))
          ) : (
            <div className="w-full flex justify-center items-center">
              <p>Aucun produit disponible</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(HistoriqueCardMobile);
