import React from "react";

const ProductHistoriqueCard = ({
  image,
  title,
  description,
  imagesection,
  price,
  quantite,
}) => {
  return (
    <div className="md:w-80 w-72 flex-shrink-0  bg-[#E8E8E8] rounded-2xl  h-full gap-y-2 flex flex-col">
      {image && (
        <div 
        className="w-full rounded-2xl h-48 bg-red-100 flex justify-center items-center object-cover" >

            <span className="text-red-400/20 font-bold text-3xl "> {title} </span>

         </div> 
       
      )}
      <div className="flex flex-col  px-4 gap-y-2">
        <h2 className="font-bold text-2xl"> {title} </h2>
        <span className="text-sm "> {description} </span>
        {imagesection ? (
          <img
            src={imagesection}
            className="object-contain h-10 w-10 sm:h-16 sm:w-16"
            alt={title}
          />
        ) : null}
        <div className="flex   justify-between items-center py-2 sm:py-2 w-full ">
          <span className="fontp  text-4xl  p-0 m-0 text-[#E51826] font-bold ">
            {price} XAF
          </span>
          <div className="h-8 w-8 rounded-lg  flex justify-center items-center border-2 text-red-500 border-red-500">
            <span className="font-bold">{quantite}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHistoriqueCard;
