import Cookies from "js-cookie";
import { redirect } from "react-router-dom";
import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import { toast } from "sonner";

const { persistAtom } = recoilPersist();

export const panier = atom({
  key: "panier",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const infoUser = atom({
  key: "infoUser",
  default: [],
});

export const CountPaner = selector({
  key: "CountPaner",
  get: ({ get }) => {
    const text = get(panier);

    return text.length;
  },
});

export const CountTotalPaner = selector({
  key: "CountTotalPaner",
  get: ({ get }) => {
    const paniera = get(panier);
    var total = paniera.reduce((prev, cur) => {
      return prev + cur.prixtotal;
    }, 0);
    return total;
  },
});

export const tokenReturn = () => {
  return Cookies.get("token77xAplD");
};

export const checkConnect = () => {
  const token = tokenReturn();
  if (token && token !== " ") {
    return true;
  }
  return false;
};

export const logOut = () => {
  Cookies.remove("token77xAplD");
  toast("vous etes deconnecté", { type: "success" });
  window.location.reload();
  redirect("./");
};


