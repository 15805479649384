import axios from "axios";
import React, { useEffect, useState } from "react";
import ComplementContext from "./context";

const ComplementProvider = ({ children }) => {
  const [complements, setComplements] = useState([]);
  const [loadingcom, setLoadingcom] = useState(false);

  const fetchComplements = async () => {
    try {
      setLoadingcom(true);
      const response = await axios.get(
        "https://cmchickenbackend.onrender.com/api/v1/auth/category/262/products"
      );

      setComplements(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingcom(false);
    }
  };

  useEffect(() => {
    fetchComplements();
  }, []);

  return (
    <ComplementContext.Provider value={{ complements, loadingcom }}>
      {children}
    </ComplementContext.Provider>
  );
};

export default ComplementProvider;
