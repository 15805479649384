import React from "react";
import { checkConnect } from "../atom";
import loupe from "./images/Search.png";
const Searchbar = () => {
  return (
    <div className="flex overflow-hidden lg:max-w-xl w-full lg:mx-3 mx-0">
      <form
        className={`flex w-full transition-all  between ease duration-300 h-12   ${
          checkConnect() ? "border" : "sm:border"
        } border-gray-800  rounded-lg overflow-hidden`}
      >
        <input
          type="text"
          className="pl-4 w-full outline-none"
          placeholder="Votre mot de recherche ici"
        />
        <button
          type="submit"
          className={` w-14 sm:w-12 ${
            checkConnect() ? "-translate-x-0" : "-translate-x-2"
          }  sm:translate-x-0  sm:bg-[#DA404A] bg-[#DF2F3B] sm:hover:bg-[#DF2F3B] rounded-lg sm:rounded-none  text-center flex justify-center items-center`}
        >
          <img
            src={loupe}
            alt="Barre de recherche"
            className="object-contain"
          />
        </button>
      </form>
    </div>
  );
};

export default Searchbar;
