import React from "react";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from "../images/hero--img.webp";
import image2 from "../images/hero--img2.webp";
const TopPositionProductCard = () => {


  return (
    <div className="w-full px-1 rounded-lg overflow-hidden">
      <div className="w-full h-[245px]  bg-yellow-400 rounded-lg  md:h-[550px]  ">
        <Swiper
          autoplay={{
            duration: 2,
            delay: 4500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          modules={[Autoplay, Pagination, Navigation]}
          scrollbar={{ draggable: true }}
          slidesPerView={1}
          navigation={true}
          className="relative w-full"
          pagination={{
            clickable: true,
          }}
        >
       <SwiperSlide key={"1"} className="w-full">
         <img src={image1} alt="image1" className="w-full h-[245px]  object-contain  md:h-[550px] "/>
        </SwiperSlide>
        <SwiperSlide key={"2"} className="w-full">
         <img src={image2} alt="image2" className="w-full h-[245px]  object-contain  md:h-[550px] "/>
        </SwiperSlide>
            
        </Swiper>
      </div>
    </div>
  );
};

export default TopPositionProductCard;
