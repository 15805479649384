import React, { memo } from "react";

const HistoryCard = ({ title, description, image, price, dateItem }) => {
  return (
    <div
      className="flex gap-x-4 w-full  py-4 border-b-2 border-[#878787
    ] justify-start items-center"
    >
      {dateItem && (
        <div className="flex flex-col justify-center items-center">
          <span className="font-bold text-md sm:text-2xl">
            {" "}
          {dateItem?.jour}{" "}
          </span>
          <span className="text-[#878787] text-[10px] sm:text-xl font-medium ">
            {" "}
            {dateItem?.mois + "/" + dateItem?.annee}{" "}
          </span>
        </div>
      )}
      <div className="flex flex-col w-8/12 justify-center items-start pl-6 h-full max-w-sm  ">
        <h4 className="font-bold text-[12px] sm:text-2xl"> {title} </h4>
        <span className="text-[#878787] truncate   text-wrap    max-w-[14rem] md:max-w-[20rem]   text-[10px] md:text-sm leading-normal  font-medium ">
          {description}{" "}
        </span>
      </div>
      {image && (
        <img
          src={image}
          alt={title}
          className=" object-contain w-10 h-10 sm:h-14 sm:w-14 "
        />
      )}
      <h5 className="font-bold text-md sm:text-4xl fontp text-red-500">
        {" "}
        {price} XAF{" "}
      </h5>
    </div>
  );
};

export default memo(HistoryCard);
