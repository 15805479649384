

        import React from 'react'
import { NavLink } from 'react-router-dom'
        
        const LinkSideBarDashboard = (propsLinkSideBarDashboard) => {
          return (
            <NavLink      to={propsLinkSideBarDashboard.link} className='w-full text-custom-black font-bold justify-start items-center flex gap-x-2 h-auto px-5 py-3'>
                <span>{propsLinkSideBarDashboard.icon}</span>
                <span> {propsLinkSideBarDashboard.title} </span>
            </NavLink>
          )
        }
        
        export default LinkSideBarDashboard
        