import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { MinusIcon, PlusIcon } from "lucide-react";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { toast } from "sonner";
import { checkConnect, infoUser, tokenReturn } from "../atom";
import momo from "../components/images/momo.jpeg";
import om from "../components/images/om.png";
import Loader from "./Loader";
import AuthModal from "./SignUp";
import LoginCardShow from "./loginCardShow";

const BuyOneProdcut = ({
  name,
  description,
  price,
  prixtotal,
  id,
  choiceComplements,
  tabcomplements,
}) => {
  const [isOpen, setIsOpen] = useState(false);


  const product = { name, description, price, prixtotal, id };
  const [location,setLocation] = useState("") 
  const [quantitep, setQuantite] = useState(1);
  const [quantitepc, setQuantityc] = useState(1);
  const [prixp, setprix] = useState(prixtotal);
  const [prixpc, setprixc] = useState(tabcomplements.price);


  const [isOpenAuthState, setIsopenAuthState] = useState(false);
  const [isopenAuthLoginState, setIsopenAuthLoginState] = useState(false);

  useEffect(() => {
    setIsopenAuthLoginState(isopenAuthLoginState)
  }, [isopenAuthLoginState]);



  const add = () => {
    const nouveauPrix = (quantitep + 1) * Number(price);
    const nouvelleQuantite = quantitep + 1;
    setprix(nouveauPrix);
    setQuantite(nouvelleQuantite);
  };

  const addComplements = () => {
    const nouveauPrix = (quantitepc + 1) * Number(tabcomplements.price);
    const nouvelleQuantite = quantitepc + 1;
    setprixc(nouveauPrix);
    setQuantityc(nouvelleQuantite);
  };

  const minus = () => {
    if (quantitep > 1) {
      const nouvelleQuantite = quantitep - 1;

      const nouveauPrix = nouvelleQuantite * Number(price);

      setQuantite(nouvelleQuantite);
      setprix(nouveauPrix);
    }
  };

  const minusComplements = () => {
    if (quantitepc > 1) {
      const nouvelleQuantite = quantitepc - 1;

      const nouveauPrix = nouvelleQuantite * Number(tabcomplements.price);

      setQuantityc(nouvelleQuantite);
      setprixc(nouveauPrix);
    }
  };

  const prixTotalAll = (choiceComplements === 1 ? prixpc  : 0 )   + prixp;

  let panierElementss = [product, tabcomplements];

  const [loading, setLoading] = useState(false);

  let datejour = new Date();
  datejour =
    datejour.getDate() +
    "/" +
    datejour.getMonth() +
    "/" +
    datejour.getFullYear() +
    "/" +
    datejour.getSeconds();
  const email = useRecoilValue(infoUser);
  const [isOpenLogInstate, setIsopenLoginInState] = useState(false);
  const [numberPhone, setNumberPhone] = useState("");

  const infotransaction = {
    email: `${email}`,
    currency: "XAF",
    amount: prixTotalAll,
    phone: numberPhone,
    reference: datejour, 
    location : location,
    description: "Alpha digital services",
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const token = tokenReturn();
  const reallyBuy = () => {
    if (checkConnect()) {
      try {
        if (numberPhone.length > 0 && numberPhone.length === 9) {
          setLoading(true);

          axios
            .post(
              "https://cmchickenbackend.onrender.com/api/public/product/buy",
              panierElementss,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                params: infotransaction,
              }
            )
            .then((res) => {
              console.log(res);
              console.log(panierElementss);
              toast("payement reussi");
              panierElementss = [];
              toast.success("veuillez valider le payement sur votre telephone");
              navigate("/historique");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setInterval(() => {
                setLoading(false);
              }, 3000);
            });
        } else {
          toast.error("veuillez saisir un numero valide de 9 chiffres  ");
        }
      } catch (err) {
        setLoading(false);
        console.log(err, "444");
      }
    } else {
      setIsopenAuthState(true);
      setIsopenAuthLoginState(true);
    }
  };

  const closeModalLogInstate = () => {
    setIsopenLoginInState(false);
  };

  useEffect(() => {}, [isOpen]);
  return (
    <div className="flex flex-col gap-y-4">
      {!isOpen && (
        <div className="flex flex-col w-full">
          <div className="w-full min-h-[64px]  flex justify-start items-center gap-x-4">
            <div className="w-1/3 flex flex-col gap-y-1">
              <h4 className="font-bold text-md sm:text-xl  "> {name} </h4>
              <p className="font-thin opacity-95  text-md text-balance ">
                {description}{" "}
              </p>
            </div>
            <div className="flex gap-x-2 justify-start items-center">
              <button onClick={() => add()} className="">
                <PlusIcon color="#E51826" size={18} />
              </button>
              <div className="h-7 w-7 border-2 text-xl flex justify-center items-center text-[#E51826] rounded-full border-[#E51826] ">
                {quantitep}
              </div>
              <button onClick={() => minus()} className="">
                <MinusIcon color="#E51826" size={18} />
              </button>
            </div>

            <div className="flex gap-x-4">
              <span className="  text-[#E51826] text-xl font-bold ">
                {prixp} XAF{" "}
              </span>
            </div>
          </div>
          {choiceComplements === 1 && <div className="w-full min-h-[64px]  flex justify-start items-center gap-x-4">
            <div className="w-1/3 flex flex-col gap-y-1">
              <h4 className="font-bold text-md sm:text-xl  ">
                {" "}
                {tabcomplements.name}
              </h4>
              <p className="font-thin opacity-95  text-md text-balance ">
                {tabcomplements.description}{" "}
              </p>
            </div>
            <div className="flex gap-x-2 justify-start items-center">
              <button onClick={() => addComplements()} className="">
                <PlusIcon color="#E51826" size={18} />
              </button>
              <div className="h-7 w-7 border-2 text-xl flex justify-center items-center text-[#E51826] rounded-full border-[#E51826] ">
                {quantitepc}
              </div>
              <button onClick={() => minusComplements()} className="">
                <MinusIcon color="#E51826" size={18} />
              </button>
            </div>

            <div className="flex gap-x-4">
              <span className="  text-[#E51826] text-xl font-bold ">
                {prixpc} XAF{" "}
              </span>
            </div>
          </div> }
          
          <div className="mt-4 flex flex-col h-full gap-y-4 gap-x-4 w-full">
            <div className="text-red-500 font-bold">
              <span className="text-black">Total : </span>{" "}
              <span>{prixTotalAll} XAF</span>
            </div>
            <span className="text-red-500 font-bold">
              {" "}
              Veuillez renseigner votre numero{" "}
            </span>
            <input
              type="tel"
              maxLength={9}
              onChange={(e) => setNumberPhone(e.target.value)}
              placeholder="votre numero ici"
              value={numberPhone}
              className="w-full h-10 border-2 border-[#E51826] rounded-lg p-2"
            />
               <div className="flex justify-start items-start py-2">
                              <img
                                src={momo}
                                className={
                                  "object-cover h-20  border-red-500/20  border"
                                }
                                alt=""
                              />
                              <img
                                src={om}
                                className={
                                  "object-cover h-20  border-red-500/20  border"
                                }
                                alt=""
                              />
                            </div>
            <span className="">
                              Entrez le lieu de livraison
                            </span>
                            <input
                              type="text"
                              onChange={(e) => setLocation(e.target.value)}
                              placeholder="votre localisation ici"
                              value={location}
                              className="border-2 rounded-lg  border-gray-400 h-10 w-full p-2"
                            />
            <button
              onClick={(e) => reallyBuy()}
              className="text-white  w-full  h-10 transition-all ease duration-400 hover:bg-[#E51826] bg-[#DA404A]   rounded-lg"
            >
              {checkConnect() ? (
                loading ? (
                  <Loader />
                ) : (
                  <span>Valider</span>
                )
              ) : (
                <span> se connecter </span>
              )}
            </button>
          </div>

      

<AuthModal
                isOpenLogin={isopenAuthLoginState}
                isOpenState={isOpenAuthState}
                onClose={() => {
                  setIsopenAuthState(false);
                  setIsopenAuthLoginState(isopenAuthLoginState);
                }}
              />

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md  flex flex-col justify-center items-center gap-y-4 transform overflow-hidden rounded-2xl bg-white px-4 text-left align-middle shadow-xl transition-all">
                      <div className="w-full py-4 flex flex-col gap-y-4">
                        <Dialog.Title
                          as="h3"
                          className="text-lg w-full font-medium leading-6 text-gray-900"
                        >
                          Confirmer vos commandes payement
                        </Dialog.Title>
                        <LoginCardShow />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      )}

      {isOpen && (
        <h2 className="w-full py-2 text-center">
          Payement reuissi veuillez fermer
        </h2>
      )}
    </div>
  );
};

export default BuyOneProdcut;
