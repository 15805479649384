import { MinusIcon, PlusIcon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { panier } from "../atom";

const CardPanier = ({ title, description, price, prixtotal, id }) => {
  const [datapaner, setProduct] = useRecoilState(panier);
  const [Trashstatus, showTrash] = useState(false);

  const product = datapaner.find((elem) => elem.id === id);

  const [quantitep, setQuantite] = useState(product?.quantity);
  const [prixp, setprix] = useState(prixtotal);

  const modifierElement = (idElement, updates) => {
    const indexElement = datapaner.findIndex(
      (element) => element.id === idElement
    );
    if (indexElement !== -1) {
      const nouveauTableau = [...datapaner];

      nouveauTableau[indexElement] = {
        ...nouveauTableau[indexElement],
        ...updates,
      };

      setProduct(nouveauTableau);
    }
  };

  const add = () => {
    const nouveauPrix = (quantitep + 1) * Number(price);
    const nouvelleQuantite = quantitep + 1;
    setprix(nouveauPrix);
    setQuantite(nouvelleQuantite);
    modifierElement(id, { quantity: quantitep + 1, prixtotal: nouveauPrix });
  };

  const minus = () => {
    if (quantitep > 1) {
      const nouvelleQuantite = quantitep - 1;

      const nouveauPrix = nouvelleQuantite * Number(price);

      setQuantite(nouvelleQuantite);
      setprix(nouveauPrix);

      modifierElement(id, {
        prixtotal: nouveauPrix,
        quantity: nouvelleQuantite,
      });
    }
  };

  const retirer = (product) => {
    const newtab = datapaner.filter((elem) => elem.id !== product.id);
    setProduct(newtab);
  };

  const enlever = () => {
    if (product) {
      retirer(product);
    }
  };

  const deletesurvol = useRef(null);
  useEffect(() => {
    if (deletesurvol.current) {
      deletesurvol.current?.addEventListener("mouseover", () => {
        showTrash(true);
      });

      deletesurvol.current?.addEventListener("mouseout", () => {
        showTrash(false);
      });
    }
  });

  return (
    <div className="w-full min-h-[64px]  flex justify-start items-center gap-x-4">
      <div className="w-1/2 flex flex-col gap-y-1">
        <h4 className="font-bold text-[12px] sm:text-[14px] w-full ">
          {title}
        </h4>
        <p className="font-medium opacity-95 text-[9px] sm:text-[14px] text-balance ">
          {description}
        </p>
      </div>
      <div className="flex gap-x-2 justify-center items-center">
        <button onClick={() => add()} className="text-3xl sm:text-xl">
          <PlusIcon color="#E51826" size={10} />
        </button>
        <div className="h-6 w-6 border-2 flex justify-center items-center text-[#E51826] rounded-full border-[#E51826] ">
          {quantitep}
        </div>
        <button onClick={() => minus()} className="text-3xl sm:text-xl">
          <MinusIcon color="#E51826" size={10} />
        </button>
      </div>

      <div className="flex gap-x-4">
        <span className="  text-[#E51826] text-[14px] font-bold ">
          {" "}
          {prixp} XAF{" "}
        </span>
        <button
          className="transition-all ease-in duration-700"
          ref={deletesurvol}
          onClick={(e) => enlever()}
        >
          {Trashstatus ? (
            <svg
              width="15"
              height="21"
              className="h-7 w-6 transition-all ease-in duration-700"
              viewBox="0 0 15 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.131 19.422C3.26329 19.9935 3.80127 20.4009 4.42391 20.4009H11.2977C11.9291 20.4009 12.4742 19.9811 12.5959 19.3991L15 7.97456H0.471108L3.131 19.4238V19.422Z"
                fill="#E51826"
              />
              <path
                d="M13.6013 0.171254L11.2694 1.01967L10.9343 0.0989359C10.9061 0.0213262 10.8197 -0.0192425 10.7403 0.00897918L7.553 1.1696L7.21786 0.248864C7.18964 0.171254 7.10321 0.130685 7.02384 0.158907L5.25998 0.800951C5.18237 0.829173 5.1418 0.915602 5.17002 0.994975L5.50516 1.91571L2.31787 3.07633C2.24026 3.10455 2.19969 3.19098 2.22791 3.27035L2.56304 4.19108L0.0989359 5.08536C0.0213262 5.11358 -0.0192425 5.20001 0.00897918 5.27938L0.344112 6.20012L14.1287 1.18194L13.7935 0.261211C13.7653 0.183601 13.6789 0.143032 13.5995 0.171254H13.6013Z"
                fill="#E51826"
              />
            </svg>
          ) : (
            <svg
              className="h-7 w-6 transition-all ease-in duration-700"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.90804 10.9318C1.99876 11.3208 2.36577 11.5984 2.79051 11.5984H7.47505C7.90666 11.5984 8.27642 11.3125 8.36027 10.9153L10.0001 3.12695H0.0963542L1.90941 10.9318H1.90804Z"
                fill="#878787"
              />
              <path
                d="M9.89691 1.54089H8.20619V0.872852C8.20619 0.816495 8.15945 0.769759 8.10309 0.769759H5.79107V0.103093C5.79107 0.0467354 5.74433 0 5.68797 0H4.40825C4.35189 0 4.30515 0.0467354 4.30515 0.103093V0.771134H1.99313C1.93677 0.771134 1.89003 0.817869 1.89003 0.874227V1.54227H0.103093C0.0467354 1.54227 0 1.589 0 1.64536V2.3134H10V1.64536C10 1.589 9.95326 1.54227 9.89691 1.54227V1.54089Z"
                fill="#878787"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default CardPanier;
