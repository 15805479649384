import React from "react";
import { NavLink } from "react-router-dom";

const Card1 = ({ image, title, description, titlelink, link }) => {
  return (
    <div className="flex sm:flex-row flex-col  rounded-xl gap-x-2  bg-white px-3  md:p-3  w-full  min-h-[135px] sm:h-[230px] md:h-[285px]  overflow-hidden">
      <div className="overflow-hidden sm:flex hidden  rounded-xl w-full sm:w-1/2">
        {image && (
          <img src={image} alt={title} className="h-full object-cover w-full" />
        ) }
      </div>
      <div className="flex gap-y-4 sm:gap-y-3 flex-col w-full  justify-between  md:h-full  px-2 sm:w-1/2">
        <div className="flex justify-start items-start flex-col gap-y-2">
        <h3 className="text-2xl  font-bold text-[#E51826] ">{title}</h3>
        <span className="text-md  lg:text-lg xl:text-xl md:max-w-[17rem] "> {description}</span>
        </div>
        <div className="flex  justify-start items-end w-full">
          <NavLink
            to={link}
            className={ 
              "p-3 rounded-lg   w-full  xl:w-1/2  text-center text-white  duration-300 bg-[#DA404A] hover:bg-[#E51826] "
            }
          >
            {titlelink}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Card1;
