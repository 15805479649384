import React from "react";

const Card5 = ({ texte, description }) => {
  return (
    <div  className="z-0 min-w-[140px] md:min-w-[300px] w-full  flex flex-col gap-y-4 sm:max-w-[290px] lg:min-w-auto justify-center mr-16 -translate-x-5 sm:translate-x-0  items-center rotate-[10deg] sm:rotate-[14deg] md:rotate-12 md:mr-36  md:mx-4 my-16 rounded-lg backdrop-blur-sm bg-gray-200/50 py-16 px-4">
      <h4 translate={"no"} className="text-[#E51826] text-xl sm:text-2xl md:text-3xl font-medium ">
        {" "}
        {texte}{" "}
      </h4>
      <span  translate="no" className=" text-[12px] md:text-lg text-center">{description}</span>
    </div>
  );
};

export default Card5;
